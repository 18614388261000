import React from 'react';
import { DefaultProfileIcon, LoginInfoIcon } from '../../assets/icons';

type ProfileFormProps = {
  name: string;
  setName: (v: string) => void;
  onPressProfile: () => void;
  profileImage: string;
};

const ProfileForm: React.FC<ProfileFormProps> = ({
  name,
  setName,
  profileImage,
  onPressProfile,
}) => {
  return (
    <div className="flex flex-col justify-between items-center">
      <button
        type="button"
        onClick={() => {
          onPressProfile();
        }}
        className="w-[6.25rem] h-[6.23rem]"
      >
        {profileImage ? (
          <img
            style={{
              backgroundSize: 'cotain',
            }}
            className="w-full h-full rounded-full"
            src={`${profileImage}`}
            alt="프로필 이미지 에러"
          />
        ) : (
          <div className="w-full h-full flex justify-center items-center bg-zp-grey01 rounded-full">
            <DefaultProfileIcon />
          </div>
        )}
      </button>
      <div>
        <div className="flex border-b border-zp-grey01 mb-2 mt-9 py-1">
          <input
            className="w-full text-zp-black01 bg-zp-white text-center leading-4"
            value={name}
            maxLength={6}
            onChange={(e) => {
              const newVal = e.target.value;
              if (newVal.length <= 6) {
                setName(newVal);
              } else if (newVal.length > 6) {
                setName(newVal.slice(0, 6));
              }
            }}
          />
          <span className="text-sm font-normal text-zp-black02 leading-4">{name.length}/6</span>
        </div>
        <div className="flex justify-center items-center mb-3">
          <LoginInfoIcon />
          <span className="text-sm font-normal text-zp-primary">6자 이내로 작성해주세요</span>
        </div>
      </div>
    </div>
  );
};

export default ProfileForm;
