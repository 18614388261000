import React from "react";

const Plus: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path fill="#FF90A0" d="M21 11H3a1 1 0 100 2h18a1 1 0 100-2z" />
      <path fill="#FF90A0" d="M11 3v18a1 1 0 102 0V3a1 1 0 10-2 0z" />
    </svg>
  );
}

export default Plus;
