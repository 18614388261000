import React from "react";

const LittleManyWorkIcon:React.FC = () =>  {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#FFB683"
        fillRule="evenodd"
        d="M18 30c6.627 0 12-5.373 12-12S24.627 6 18 6 6 11.373 6 18s5.373 12 12 12zm0 3c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C9.716 3 3 9.716 3 18c0 8.284 6.716 15 15 15z"
        clipRule="evenodd"
       />
      <path
        fill="#FFB683"
        d="M16.5 15a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM22.5 15a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
       />
      <path
        fill="#FFB683"
        fillRule="evenodd"
        d="M10.94 19.94a1.5 1.5 0 012.12 0L15 21.878l1.94-1.94a1.5 1.5 0 012.12 0L21 21.88l1.94-1.94a1.5 1.5 0 012.12 2.122l-3 3a1.5 1.5 0 01-2.12 0L18 23.12l-1.94 1.94a1.5 1.5 0 01-2.12 0l-3-3a1.5 1.5 0 010-2.122z"
        clipRule="evenodd"
       />
    </svg>
  );
}

export default LittleManyWorkIcon;
