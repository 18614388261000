import React from "react";

const ClosePageIcon:React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M4.929 17.657a1 1 0 001.414 1.414L12 13.414l5.657 5.657a1 1 0 001.414-1.414L13.414 12l5.657-5.657a1 1 0 00-1.414-1.414L12 10.586 6.343 4.929A1 1 0 104.93 6.343L10.586 12l-5.657 5.657z"
        clipRule="evenodd"
       />
    </svg>
  );
}

export default ClosePageIcon;
