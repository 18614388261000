import React from "react";

type CheckProps = {
  color?: string;
}

const CheckWorkIcon: React.FC<CheckProps> = ({color = '#6CDC54'}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="10"
      fill="none"
      viewBox="0 0 14 10"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M4.94 9.425L.706 5.192a1 1 0 111.414-1.414l3.536 3.536L12.021.95a1 1 0 111.414 1.414L6.364 9.435a1 1 0 01-1.425-.01z"
        clipRule="evenodd"
       />
    </svg>
  );
}

export default CheckWorkIcon;
