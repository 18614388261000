import { atom } from 'recoil';

export const loadingState = atom({
  key: 'LoadingState',
  default: false,
});

export const toastMessagesState = atom<string[]>({
  key: 'ToastMessagesState',
  default: []
})
