import React from 'react';
import { CreateItemButtonWrapper } from './S';
import { AddWorkIcon, WriteHeartIcon } from '../../assets/icons';
import { disabledColor, whiteColor } from '../../theme/colors';

type CreateItemButtonProps = {
  mode: 'work' | 'heart';
  disabled: boolean;
  onPress: () => void;
};

const CreateItemButton: React.FC<CreateItemButtonProps> = ({ mode, onPress, disabled }) => {
  return (
    <CreateItemButtonWrapper disabled={disabled} onClick={onPress}>
      {mode === 'work' ? (
        <AddWorkIcon color={disabled ? disabledColor : whiteColor} />
      ) : (
        <WriteHeartIcon color={disabled ? disabledColor : whiteColor} />
      )}
    </CreateItemButtonWrapper>
  );
};

export default CreateItemButton;
