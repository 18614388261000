import React from "react";

const HeartEmptyIcon:React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="168"
      fill="none"
      viewBox="0 0 140 168"
    >
      <path
        fill="#E8E8E8"
        d="M72.97 64.57a4.2 4.2 0 010 5.94l-53.457 53.457a4.2 4.2 0 01-5.94-5.94L67.03 64.57a4.2 4.2 0 015.94 0z"
       />
      <path
        fill="#E8E8E8"
        d="M67.03 64.57a4.2 4.2 0 015.94 0l53.457 53.457a4.2 4.2 0 01-5.94 5.94L67.03 70.51a4.2 4.2 0 010-5.94zM63 110.6a4.2 4.2 0 11-8.4 0 4.2 4.2 0 018.4 0zM85.4 110.6a4.2 4.2 0 11-8.4 0 4.2 4.2 0 018.4 0z"
       />
      <path
        fill="#E8E8E8"
        fillRule="evenodd"
        d="M70.4 151.949a9.756 9.756 0 01-6 2.051h-.985a12.6 12.6 0 01-12.502-11.037l-.1-.796a15.4 15.4 0 015.05-13.42l11.347-10.086a4.199 4.199 0 015.58 0l11.347 10.086a15.4 15.4 0 015.05 13.42l-.126 1.008A12.358 12.358 0 0176.798 154a10.95 10.95 0 01-6.398-2.051zm-4.6-8.943a4.2 4.2 0 018.4-.003 2.598 2.598 0 002.598 2.597 3.958 3.958 0 003.928-3.467l.126-1.008a7 7 0 00-2.296-6.1L70 127.419l-8.556 7.606a7 7 0 00-2.296 6.1l.1.796a4.2 4.2 0 004.167 3.679h.985a1.4 1.4 0 001.4-1.4v-1.194zM44.8 30.8c0-9.278 7.522-16.8 16.8-16.8h16.8c9.278 0 16.8 7.522 16.8 16.8s-7.522 16.8-16.8 16.8h-2.46l-3.96 3.96a2.8 2.8 0 01-3.96 0l-3.96-3.96H61.6c-9.278 0-16.8-7.522-16.8-16.8zM70 40.6c3.866 0 7-2.925 7-6.533 0-2.705-3.932-8.695-5.902-11.527a1.33 1.33 0 00-2.196 0C66.932 25.372 63 31.362 63 34.067c0 3.608 3.134 6.533 7 6.533z"
        clipRule="evenodd"
       />
    </svg>
  );
}

export default HeartEmptyIcon;
