import React from "react";

const TypoLogoIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="108"
      height="30"
      fill="none"
      viewBox="0 0 108 30"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M93.9 11.998a3.3 3.3 0 110 .004v-.004zm-.196-5.24a6.3 6.3 0 11.196 10.61V22.5a1.5 1.5 0 11-3 0v-15a1.5 1.5 0 012.804-.742zM79.481 10.437C79.678 9.28 80.462 8.7 81.3 8.7s1.622.58 1.819 1.737c.004.028.01.056.016.085l.654 2.978H78.81l.654-2.978a1.61 1.61 0 00.016-.085zm-2.954-.524C76.947 7.496 78.84 5.7 81.3 5.7c2.46 0 4.352 1.796 4.773 4.213l2.692 12.265a1.5 1.5 0 11-2.93.644L84.447 16.5h-6.294l-1.388 6.322a1.5 1.5 0 01-2.93-.644l2.692-12.265zM59.7 7.5a1.5 1.5 0 013 0v6h6v-6a1.5 1.5 0 013 0v15a1.5 1.5 0 01-3 0v-6h-6v6a1.5 1.5 0 01-3 0v-15z"
        clipRule="evenodd"
       />
      <path
        fill="#fff"
        d="M47.7 15a1.5 1.5 0 011.5-1.5h5.4a1.5 1.5 0 010 3h-5.4a1.5 1.5 0 01-1.5-1.5z"
       />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M35.4 11.998a3.3 3.3 0 110 .004v-.004zm-.196-5.24a6.3 6.3 0 11.196 10.61V22.5a1.5 1.5 0 11-3 0v-15a1.5 1.5 0 012.804-.742zM20.4 7.5A1.5 1.5 0 0121.9 6h6a1.5 1.5 0 110 3h-1.5v12h1.5a1.5 1.5 0 110 3h-6a1.5 1.5 0 010-3h1.5V9h-1.5a1.5 1.5 0 01-1.5-1.5z"
        clipRule="evenodd"
       />
      <path
        fill="#fff"
        d="M11.885 7.13a1.5 1.5 0 011.342-.83h2.223a1.5 1.5 0 010 3h-3.68a.6.6 0 01-.536-.868l.651-1.303z"
       />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M16.792 8.47a1.5 1.5 0 10-2.684-1.34L6.772 21.8A1.502 1.502 0 008.132 24H15.9a1.5 1.5 0 000-3h-5.373l6.265-12.528zM10.137 4.027a1.2 1.2 0 01.536 1.61l-2.1 4.2a1.2 1.2 0 01-2.146 0l-1.5-3a1.2 1.2 0 012.146-1.074l.427.854 1.027-2.054a1.2 1.2 0 011.61-.536z"
        clipRule="evenodd"
       />
    </svg>
  );
}

export default TypoLogoIcon;
