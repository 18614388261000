import React from "react";

const SmallProfileIcon:React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      fill="none"
      viewBox="0 0 16 18"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11 5a3 3 0 11-6 0 3 3 0 016 0zm.205 3.838a5 5 0 10-6.41 0c-.914.5-1.746 1.208-2.452 2.09A10.453 10.453 0 00.61 14.174 11.917 11.917 0 00.16 16.01C-.017 17.099.895 18 2 18h12c1.104 0 2.017-.901 1.84-1.991a11.913 11.913 0 00-.449-1.836 10.452 10.452 0 00-1.734-3.244c-.706-.883-1.538-1.592-2.452-2.091zm2.287 5.964c.129.388.234.788.314 1.198H2.194c.08-.41.186-.81.314-1.198a8.456 8.456 0 011.398-2.624c.583-.73 1.252-1.278 1.953-1.64A4.638 4.638 0 018 10c.717 0 1.444.176 2.142.537.7.363 1.37.912 1.953 1.641a8.454 8.454 0 011.397 2.624zm.51 1.198z"
        clipRule="evenodd"
       />
    </svg>
  );
}

export default SmallProfileIcon;
