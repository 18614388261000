import { atom, selector, selectorFamily } from "recoil";
import axios from "axios";
import { CreateGroupResponse } from "../../api/group";
import { API_URL } from "../../config/app";
import { userState } from "../user/atom";

export const currentGroupState = atom<CreateGroupResponse | null>({
  key: "CurrentGroupState",
  default: selector({
    key: 'CurrentGroupState/Default',
    get: async () => {
      try {
        const currentGroupId = sessionStorage.getItem('currentGroupLinkId');
        const userToken = sessionStorage.getItem('accessToken');
        console.debug(currentGroupId, 'userToken userState');
        if (!currentGroupId || !userToken) return null;
        const group = await axios.get(`${API_URL}/group/info/${currentGroupId}`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        console.log("group", group.data.data);
        const { data: groupData } = group.data;
        const { groupName: createGroupName, zipHapGroupId, groupPersonInfo } = groupData;
        const me = groupPersonInfo?.me ?? null;
        const author = groupPersonInfo?.author ?? null;
        const isOwner = me?.memberAuthorityRoles === '[OWNER]';
        return ({
          joinLink: `https://ziphap.swygbro.com/join${currentGroupId}`,
          linkId: currentGroupId,
          group_id: zipHapGroupId,
          groupName: createGroupName,
          ownerId: isOwner ? me.memberId : author?.memberId ?? '',
          ownerName: isOwner ? me.memberName : author?.memberName ?? '',
          memberId: !isOwner ? me.memberId : author?.memberId ?? '',
          memberName: !isOwner ? me.memberName : author?.memberName ?? '',
        })
      } catch (e) {
        console.debug('GET PROFILE ERROR', e);
        sessionStorage.removeItem('accessToken');
        return null;
      }
    },
  })
})

export const groupByIdState = selectorFamily<(CreateGroupResponse | null), string>({
  key: 'GroupByIdState',
  get: (linkId) => async ({ get }) => {
    try {
      const userToken = sessionStorage.getItem('accessToken');
      console.debug(linkId, 'userToken userState');
      if (!linkId || !userToken) return null;
      const group = await axios.get(`${API_URL}/group/info/${linkId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      const user = get(userState);
      console.log("group", group.data.data);
      const { data: groupData } = group.data;
      const { groupName: createGroupName, zipHapGroupId, groupPersonInfo } = groupData;
      const isOwner = user?.memberRole === 'OWNER';
      const me = groupPersonInfo?.me ?? null;
      const author = groupPersonInfo?.author ?? null;
      return ({
        joinLink: `https://ziphap.swygbro.com/join${linkId}`,
        linkId,
        group_id: zipHapGroupId,
        groupName: createGroupName,
        ownerId: isOwner ? me.memberId : author?.memberId ?? '',
        ownerName: isOwner ? me.memberName : author?.memberName ?? '',
        memberId: !isOwner ? me.memberId : author?.memberId ?? '',
        memberName: !isOwner ? me.memberName : author?.memberName ?? '',
      })
    } catch (e) {
      console.debug('GET PROFILE ERROR', e);
      return null;
    }
  },
})
