import React from 'react';
import { IconProps } from './ChartIcon';

const HeartIcon: React.FC<IconProps> = ({ width = 36, height = 36, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 36 36"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M6.053 12a5.251 5.251 0 0110.447.75 1.5 1.5 0 003 0A5.25 5.25 0 0129.947 12a1.495 1.495 0 00-.618.158L18 17.823 6.67 12.158c-.198-.1-.408-.15-.617-.157zm4.62 10.948l.122.088L18 28.159l7.205-5.123.122-.088-3.623-3.623-3.033 1.517a1.5 1.5 0 01-1.342 0l-3.033-1.517-3.623 3.623zm-2.168-2.074a11.407 11.407 0 01-2.408-5.649l5.37 2.686-2.962 2.963zm21.398-5.649a11.406 11.406 0 01-2.408 5.649l-2.963-2.963 5.371-2.686zM11.25 4.5A8.24 8.24 0 0118 8.005a8.25 8.25 0 0115 4.745v.992c0 4.663-2.257 9.037-6.056 11.739l-8.075 5.741a1.5 1.5 0 01-1.738 0l-8.075-5.741A14.404 14.404 0 013 13.742v-.992a8.25 8.25 0 018.25-8.25z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default HeartIcon;
