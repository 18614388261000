import React from "react";

const WorkButtonIcon:React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#A4A2A2"
        d="M2 8a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zM4 16a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z"
      />
    </svg>
  );
}

export default WorkButtonIcon;
