import axios from "axios";
import { API_URL } from "../config/app";

type CreateGroupInput = {
  groupName: string;
  ownerName: string;
}

export type CreateGroupResponse = {
  joinLink?: string;
  ownerId: string;
  ownerName: string;
  memberId?: string;
  memberName?: string;
  linkId: string;
  group_id: string;
  groupName: string;
}

export const createGroup = async ({
  groupName,
  ownerName
}:CreateGroupInput): Promise<CreateGroupResponse> => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
    }
    axios.post(`${API_URL}/group/create`,{
      groupName,
      memberName: ownerName
    }, { headers }).then(res => {
      console.log("createGroup Data", res);
      const { data } = res.data;
      const { uri, uriId } = data;
      axios.get(`${API_URL}/group/info/${uriId}`, { headers })
        .then(groupRes => {
          console.log("groupRes", groupRes.data);
          const { data: groupData } = groupRes.data;
          const { groupName: createGroupName, zipHapGroupId, groupPersonInfo } = groupData;
          const me = groupPersonInfo?.me ?? null;
          const author = groupPersonInfo?.author ?? null;
          const isOwner = me?.memberAuthorityRoles === '[OWNER]';

          resolve({
            joinLink: uri,
            linkId: uriId,
            group_id: zipHapGroupId,
            groupName: createGroupName,
            ownerId: isOwner ? me.memberId : author?.memberId ?? '',
            ownerName: isOwner ? me.memberName : author?.memberName ?? '',
            memberId: !isOwner ? me.memberId : author?.memberId ?? '',
            memberName: !isOwner ? me.memberName : author?.memberName ?? '',
          })
        })
    })
      .catch(err => {
        console.log(err);
        reject();
      })
  })
};


export const loadMyGroup = async (token: string): Promise<CreateGroupResponse | null> => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: `Bearer ${token}`
    }
    axios.get(`${API_URL}/util/login-member-group`,{
      headers,
    }).then((res) => {
      console.log(res.data, "load group");
      const uriId = res.data.data.groupInfo.linkId ?? ''
      console.log(uriId);
      axios.get(`${API_URL}/group/info/${uriId}`, { headers })
        .then(groupRes => {
          const { data: groupData } = groupRes.data;
          console.log(groupData);
          const { groupName: createGroupName, zipHapGroupId, groupPersonInfo } = groupData;
          const me = groupPersonInfo?.me ?? null;
          const author = groupPersonInfo?.author ?? null;
          const isOwner = me?.memberAuthorityRoles === '[OWNER]';

          resolve({
            joinLink: `https://ziphap.swygbro.com/join/${uriId}`,
            linkId: uriId,
            group_id: zipHapGroupId,
            groupName: createGroupName,
            ownerId: isOwner ? me.memberId ?? '' : author?.memberId ?? '',
            ownerName: isOwner ? me.memberName ?? '' : author?.memberName ?? '',
            memberId: !isOwner ? me.memberId ?? '' : author?.memberId ?? '',
            memberName: !isOwner ? me.memberName ?? '' : author?.memberName ?? '',
          })
        })
    })
    .catch((err) => {
      console.log(err);
      resolve(null)
    })
  })
}


export const joinGroup = async (linkId: string, memberName: string): Promise<CreateGroupResponse | null> => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
    }
    console.log(headers);
    axios.post(`${API_URL}/group/join/${linkId}?memberName=${memberName}`,{}, {
      headers
    }).then((res) => {
      console.log("join Data", res);
      const { data } = res.data;
      const { uri, uriId } = data;
      axios.get(`${API_URL}/group/info/${uriId}`, { headers })
        .then(groupRes => {
          const { data: groupData } = groupRes.data;
          const { groupName: createGroupName, zipHapGroupId, groupPersonInfo } = groupData;
          const me = groupPersonInfo?.me ?? null;
          const author = groupPersonInfo?.author ?? null;
          const isOwner = me?.memberAuthorityRoles === '[OWNER]';
          resolve({
            joinLink: uri,
            linkId: uriId,
            group_id: zipHapGroupId,
            groupName: createGroupName,
            ownerId: isOwner ? me.memberId ?? '' : author?.memberId ?? '',
            ownerName: isOwner ? me.memberName ?? '' : author?.memberName ?? '',
            memberId: !isOwner ? me.memberId ?? '' : author?.memberId,
            memberName: !isOwner ? me.memberName ?? '' : author?.memberName ?? '',
          })
        })
    })
      .catch((err) => {
        console.log(err);
        reject()
      })
  })
}
