import React from 'react';

const ProfileIcon: React.FC = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <circle cx={12} cy={12} r={12} fill="#FFDEE3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0001 9.33333C14.0001 10.4379 13.1046 11.3333 12.0001 11.3333C10.8955 11.3333 10.0001 10.4379 10.0001 9.33333C10.0001 8.22876 10.8955 7.33333 12.0001 7.33333C13.1046 7.33333 14.0001 8.22876 14.0001 9.33333ZM14.1369 11.8918C14.8682 11.2803 15.3334 10.3612 15.3334 9.33333C15.3334 7.49238 13.841 6 12.0001 6C10.1591 6 8.66672 7.49238 8.66672 9.33333C8.66672 10.3612 9.13192 11.2803 9.86325 11.8918C9.25392 12.2249 8.6995 12.6976 8.22882 13.286C7.73357 13.905 7.34072 14.6399 7.0727 15.4488C6.94151 15.8447 6.84148 16.2545 6.77354 16.6724C6.65538 17.3992 7.26367 18 8.00005 18L16.0001 18C16.7364 18 17.3447 17.3992 17.2266 16.6724C17.1586 16.2545 17.0586 15.8447 16.9274 15.4488C16.6594 14.6399 16.2665 13.905 15.7713 13.286C15.3006 12.6976 14.7462 12.2249 14.1369 11.8918ZM12.0001 12.6667C11.5218 12.6667 11.0376 12.7838 10.5721 13.0248C10.1049 13.2668 9.65903 13.6326 9.26998 14.1189C8.88065 14.6055 8.56039 15.1981 8.33835 15.8682C8.25271 16.1266 8.18269 16.3936 8.12902 16.6667L15.8711 16.6667C15.8174 16.3936 15.7474 16.1266 15.6618 15.8682C15.4397 15.1981 15.1195 14.6055 14.7301 14.1189C14.3411 13.6326 13.8952 13.2668 13.428 13.0248C12.9625 12.7838 12.4784 12.6667 12.0001 12.6667ZM16.0019 16.6665C16.0019 16.6666 16.0016 16.6666 16.001 16.6666L16.0019 16.6665Z"
      fill="white"
    />
  </svg>
);

export default ProfileIcon;
