import React, { useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import update from "immutability-helper";
import {
  CheckWorkIcon,
  DelayWorkIcon,
  FailWorkIcon,
  SmallProfileIcon,
  WorkButtonIcon
} from "../../assets/icons";
import { updateWorkStatus, WorkType } from "../../api/work";
import Modal from "../Modal/Modal";
import { currentGroupState } from "../../recoil/group/atoms";
import { workByGroupState, workByIdState } from "../../recoil/work/atoms";
import { loadingState } from "../../recoil/atoms";
import { isOwnerState } from "../../recoil/user/atom";

type WorkListProps = {
  works: WorkType[];
  isOwner: boolean;
}

type WorkItemModalProps = {
  currentWorkId: string;
  disabled?: boolean;
  isOpen: boolean;
  setOpen: (v: boolean) => void;
  done: () => void;
  fail: () => void;
  delay: () => void;
}

export const WorkItemModal: React.FC<WorkItemModalProps> = ({ isOpen, setOpen, done, fail, delay,disabled = false, currentWorkId }) => {
  const [workById] = useRecoilState(workByIdState(currentWorkId));
  const isAlreadyDelay = workById?.houseWorkStatusType === 'DELAY';
  return (
    <Modal isOpen={isOpen} setIsOpen={setOpen}>
      <div
        className="
          absolute bottom-0 w-full
          overflow-hidden mx-6
          flex flex-col justify-center items-center
          p-5 rounded-3xl
        "
      >
        <div className="flex flex-col w-full rounded-xl bg-zp-white py-4 mb-4">
          <button
            className="
              py-3 flex justify-start items-center
              text-xl text-tr-black01 font-normal leading-5 mx-6 border-b
            "
            type="button"
            onClick={done}
          >
            <div
              className={`
                  w-[24px] h-[24px] border rounded-lg flex justify-center items-center mr-2
                  border-zp-black03
                `}
            >
              <CheckWorkIcon color="#1C1515" />
            </div>
            <span className="text-xl leading-5 font-normal text-zp-black03">완료하기</span>
          </button>
          <button
            className="
              py-3 flex justify-start items-center
              text-xl text-tr-black01 font-normal leading-5 mx-6 border-b
            "
            type="button"
            onClick={fail}
          >
            <div
              className={`
                  w-[24px] h-[24px] border rounded-lg flex justify-center items-center mr-2
                  border-zp-black03
                `}
            >
              <FailWorkIcon color="#1C1515" />
            </div>
            <span className="text-xl leading-5 font-normal text-zp-black03">안하기</span>
          </button>
          <button
            className="
              py-3 flex justify-start items-center
              text-xl text-tr-black01 font-normal leading-5 mx-6
            "
            type="button"
            disabled={isAlreadyDelay}
            onClick={delay}
          >
            <div
              className={`
                  w-[24px] h-[24px] border rounded-lg flex justify-center items-center mr-2
                  ${isAlreadyDelay ? "border-zp-black02" : "border-zp-black03"}
                `}
            >
              <DelayWorkIcon color={isAlreadyDelay ? "#D1D0D0" : "#1C1515"} />
            </div>
            <span className={`text-xl leading-5 font-normal ${isAlreadyDelay ? "text-zp-black02" : "text-zp-black03"}`}>미루기</span>
          </button>
        </div>
        <button
          className="
            w-full rounded-xl bg-zp-white py-4
            text-xl text-tr-black01 font-semibold leading-5 mx-6
          "
          type="button"
          onClick={() => {
            setOpen(false);
          }}
        >
          실행취소
        </button>
      </div>
    </Modal>
  );
};

const WorkList: React.FC<WorkListProps> = ({ works, isOwner }) => {
  const group = useRecoilValue(currentGroupState);
  const setLoading = useSetRecoilState(loadingState);
  const [workItemModalVisible, setWorkItemModalVisible] = useState(false)
  const [currentWorkId, setCurrentWorkId] = useState("");
  const [allWorks, setWorks] = useRecoilState(workByGroupState);
  const owner = useRecoilValue(isOwnerState);
  const updateStatus = async (status: "DEFAULT" | "COMPLETED" | "FAILURE" | "DELAY") => {
    try {
      setLoading(true);
      const complete = await updateWorkStatus({
        workId: currentWorkId,
        status
      });
      if (!complete) return false;
      const findIndex = allWorks[isOwner ? 0 : 1]?.findIndex(work => work?.houseWorkId === currentWorkId);
      setWorks(update(allWorks, {
        [isOwner ? 0 : 1]: {
          [findIndex] : {
            houseWorkStatusType: { $set: status }
          }
        }
      }))
      setCurrentWorkId('');
      setWorkItemModalVisible(false);
      console.log('done')
    } catch (err) {
      console.warn(err);
    } finally {
      setLoading(false);
    }
  }

  if (!works.length) return null;
  return (
    <>
      <WorkItemModal
        currentWorkId={currentWorkId}
        isOpen={workItemModalVisible}
        setOpen={(v) => {
          if(!v) {
            setCurrentWorkId("")
          }
          setWorkItemModalVisible(v)
        }}
        done={() => updateStatus("COMPLETED")}
        fail={() => updateStatus("FAILURE")}
        delay={() => updateStatus("DELAY")}
      />
      <div
        className="
          rounded-[20px] bg-white
          shadow-[0_0_5px_0_#1C151532]
          p-5 mb-4
        "
      >
        <div className="flex items-center pb-3 border-b border-b-zp-black02">
          <div className={`flex justify-center items-center w-[32px] h-[32px] ${isOwner ? 'bg-zp-primary' : 'bg-zp-secondary'}  rounded-full`}>
            <SmallProfileIcon />
          </div>
          <span className="text-zp-black03 text-lg leading-5 font-semobold ml-3">
            {isOwner ? group?.ownerName ?? '' : group?.memberName ?? ''}
          </span>
        </div>
        <div className="py-1">
          {works?.length ? (
            works.map(work => {
              const 완료 = work.houseWorkStatusType === "COMPLETED";
              const 활성 = work.houseWorkStatusType === "DEFAULT";
              const 실패 = work.houseWorkStatusType === "FAILURE";
              const 미루기 = work.houseWorkStatusType === "DELAY";
              return (
                <button
                  type="button"
                  className="w-full py-2 border-b border-b-zp-grey01 flex justify-between items-center"
                  onClick={() => {
                    if (실패 || owner !== isOwner ) return false;
                    setCurrentWorkId(work?.houseWorkId ?? '');
                    setWorkItemModalVisible(true);
                  }}
                >
                  <div className="flex items-center">
                    <div
                      className={`
                  w-[24px] h-[24px] border rounded-lg flex justify-center items-center mr-2
                  ${완료 || 활성 ? `${isOwner ? "border-zp-primary" : "border-zp-secondary"}` : "border-zp-black02"}
                `}
                    >
                      {완료 ? <CheckWorkIcon color={isOwner ? "#FF90A0" : "#6CDC54"} />: null}
                      {미루기 ? <DelayWorkIcon />: null}
                      {실패 ? <FailWorkIcon />: null}
                    </div>
                    <span className="leading-4 text-base text-zp-black03 font-normal">
                {work?.title ?? ''}
              </span>
                  </div>
                  {완료 || 활성 ? (
                    <WorkButtonIcon />
                  ) : null}
                </button>
              )
            })
          ): null}
        </div>
      </div>
    </>
  )
}

export default WorkList;
