import React from 'react';

export type IconProps = {
  width?: number;
  height?: number;
  color?: string;
};

const ChartIcon: React.FC<IconProps> = ({ width = 36, height = 36, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 36 36"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M19.5 4.5A1.5 1.5 0 0018 3a15 15 0 1014.266 10.365 1.5 1.5 0 00-2.853.927A12 12 0 1118 6a1.5 1.5 0 001.5-1.5z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        d="M13.757 22.243a1.5 1.5 0 010-2.121l6.364-6.364a1.5 1.5 0 012.122 2.12l-6.364 6.365a1.5 1.5 0 01-2.122 0zM16.5 15a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM22.5 21a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
      />
    </svg>
  );
};

export default ChartIcon;
