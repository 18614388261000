import React, { useMemo } from "react";
import { ArrowIcon } from "../../assets/icons";
import { pad } from "../../util/date";

type DateTimeSelectorProps = {
  selectMode: string;
  date: Date;
  prevDate: () => void;
  nextDate: () => void;
}

export const DateTimeSelector:React.FC<DateTimeSelectorProps> = ({
  selectMode,
  date,
  prevDate,
  nextDate
}) => {
  const currentDate = useMemo(() => {
    return date.getMonth() + 1;
  }, [date]);

  const monthdays: number[][] = useMemo(() => {
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1).getUTCDate();
    const daysOfWeek = new Date(`${date.getFullYear()}-${pad(date.getMonth() + 1)}-01`).getDay();
    const monthArr = new Array(6).fill(new Array(7).fill(0));
    return monthArr.map((e, i) => {
      return e.map((v: number, vi: number) => {
        const index = 7 * i + vi;
        if (daysOfWeek > index) return undefined;
        if (lastDay < index - daysOfWeek + 1) return undefined;
        return index - daysOfWeek + 1;
      });
    });
  }, [date]);

  const currentWeek = useMemo(() => {
    const day = date.getDate();
    let index = 0;
    monthdays.forEach((week, weekIndex) => {
      week.forEach((e, i) => {
        if(e === day) {
          index = weekIndex + 1;
        }
      })
    });
    return index;
  }, [date, monthdays]);
  return (
    <div className="flex">
      <button type="button" className="p-1 pr-2" onClick={prevDate}>
        <ArrowIcon />
      </button>
      <div className="text-base leading-4 font-semibold text-zp-black03 mx-1">
        {currentDate}월
      </div>
      <button className="rotate-180 p-1 pr-2" type="button" onClick={nextDate}>
        <ArrowIcon />
      </button>
    </div>
  );
}

export default DateTimeSelector;
