import React from 'react';
import { IconProps } from './ChartIcon';

const HomeIcon: React.FC<IconProps> = ({ width = 36, height = 36, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 36 36"
    >
      <path fill={color} d="M15 15a1.5 1.5 0 011.5-1.5h3a1.5 1.5 0 010 3h-3A1.5 1.5 0 0115 15z" />
      <path
        fill={color}
        fillRule="evenodd"
        d="M19.06 5.197a1.5 1.5 0 00-2.12 0l-.012.011L4.211 17.925a1.5 1.5 0 102.122 2.121L18 8.379l11.667 11.667a1.5 1.5 0 102.122-2.121L19.06 5.197z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M12 22.5v6h12v-6H12zm-1.5-3A1.5 1.5 0 009 21v9a1.5 1.5 0 001.5 1.5h15A1.5 1.5 0 0027 30v-9a1.5 1.5 0 00-1.5-1.5h-15z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default HomeIcon;
