import React, { Suspense } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import styled from 'styled-components';
import { RecoilRoot } from 'recoil';
import Tab1 from './pages/HomePage';
import Tab2 from './pages/ChartPage';
import HeartPage from './pages/HeartPage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import BottomTabBar from './components/BottomTabBar';
import LoginPageComponent from './pages/LoginPage.component';
import GroupPage from './pages/GroupPage';
import KakaoLoginRedirect from './pages/KakaoLoginRedirect';
import GoogleLoginRedirect from './pages/GoogleLoginRedirect';
import Loading from "./components/Loading";
import ToastProvider from "./ToastsProvider";
import CreateWorkPage from "./pages/CreateWorkPage";
import JoinPage from "./pages/JoinPage";
import CreateHeartPage from "./pages/CreateHeart";
import OtherMenuPage from "./pages/OtherMenuPage";
import ReplyHeartPage from "./pages/ReplyLetter";

setupIonicReact();

const MainRouter: React.FC = () => {

  return (
    <>
      <Container>
        <Outlet>
          <Route exact path="/home">
            <Tab1 />
            <BottomTabBar />
          </Route>
          <Route exact path="/chart">
            <Tab2 />
            <BottomTabBar />
          </Route>
          <Route path="/heart">
            <HeartPage />
            <BottomTabBar />
          </Route>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
        </Outlet>
      </Container>
    </>
  );
};

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <RecoilRoot>
          <React.Suspense>
            <S>
            <Container>
              <ToastProvider />
              <Outlet>
                <Route exact path="/login">
                  <LoginPageComponent />
                </Route>
                <Route exact path="/group">
                  <GroupPage />
                </Route>
                <MainRouter />
                <Route exact path="/">
                  <Redirect to="/login" />
                </Route>
                <Route exact path="/kakao">
                  <KakaoLoginRedirect />
                </Route>
                <Route exact path="/google">
                  <GoogleLoginRedirect />
                </Route>
                <Route exact path="/create-work">
                  <CreateWorkPage />
                </Route>
                <Route exact path="/create-heart">
                  <CreateHeartPage />
                </Route>
                <Route path="/reply-heart/:heartId">
                  <ReplyHeartPage />
                </Route>
                <Route exact path="/other-menu">
                  <OtherMenuPage />
                </Route>
                <Route exact path="/join/:linkId">
                  <JoinPage />
                </Route>
              </Outlet>
            </Container>
            </S>
          </React.Suspense>
        </RecoilRoot>
      </IonReactRouter>
    </IonApp>
  );
};

const S = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  display: block;

  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  max-width: 420px;
  input:focus { outline: none; }
`;

const Outlet = styled(IonRouterOutlet)`
  max-width: 420px;
  //padding: max(4vh, env(safe-area-inset-left)) 0 max(4vh, env(safe-area-inset-right));
`;

export default App;
