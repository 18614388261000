import React from "react";

const XsProfileIcon:React.FC = () =>  {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8 7.333a2 2 0 100-4 2 2 0 000 4zm0 1.334A3.333 3.333 0 108 2a3.333 3.333 0 000 6.667z"
        clipRule="evenodd"
       />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11.871 12.667a6.558 6.558 0 00-.21-.799 5.64 5.64 0 00-.93-1.749 4.098 4.098 0 00-1.303-1.094A3.092 3.092 0 008 8.667c-.478 0-.962.117-1.428.358a4.098 4.098 0 00-1.302 1.094c-.39.487-.71 1.08-.932 1.75a6.56 6.56 0 00-.209.798h7.742zm.13 0zm-8.003 0H4zm9.229.006C13.345 13.399 12.737 14 12 14H4c-.736 0-1.345-.6-1.226-1.327a7.94 7.94 0 01.299-1.224 6.968 6.968 0 011.156-2.163 5.43 5.43 0 011.73-1.445A4.425 4.425 0 018 7.333c.7 0 1.394.173 2.041.508a5.43 5.43 0 011.73 1.445c.495.62.888 1.354 1.156 2.163.132.396.232.806.3 1.224z"
        clipRule="evenodd"
       />
    </svg>
  );
}

export default XsProfileIcon;
