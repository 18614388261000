import React from 'react';

const GoogleIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
      <path
        fill="#4285F4"
        fillRule="evenodd"
        d="M17.64 9.2c0-.64-.06-1.25-.16-1.84H9v3.48h4.84a4.15 4.15 0 01-1.8 2.72v2.26h2.91c1.7-1.57 2.68-3.87 2.68-6.62h.01z"
        clipRule="evenodd"
      />
      <path
        fill="#34A853"
        fillRule="evenodd"
        d="M9 18c2.43 0 4.47-.81 5.96-2.18l-2.91-2.26c-.81.54-1.84.86-3.05.86-2.34 0-4.33-1.58-5.04-3.71h-3v2.33A8.993 8.993 0 009 18z"
        clipRule="evenodd"
      />
      <path
        fill="#FBBC05"
        fillRule="evenodd"
        d="M3.96 10.71c-.18-.54-.28-1.12-.28-1.71 0-.59.1-1.17.28-1.71V4.96h-3a8.976 8.976 0 000 8.08l3.01-2.33h-.01z"
        clipRule="evenodd"
      />
      <path
        fill="#EA4335"
        fillRule="evenodd"
        d="M9 3.58c1.32 0 2.51.45 3.44 1.35l2.58-2.58C13.46.9 11.42.01 9 .01 5.48 0 2.44 2.02.96 4.96l3.01 2.33c.71-2.13 2.69-3.71 5.04-3.71H9z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default GoogleIcon;
