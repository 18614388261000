import React from 'react';

type IconProps = {
  width?: number;
  height?: number;
  color?: string;
}

const DefaultProfileIcon: React.FC<IconProps> = ({width = 40, height = 46, color = '#A4A2A2'}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5 13C27.5 17.1421 24.1421 20.5 20 20.5C15.8578 20.5 12.5 17.1421 12.5 13C12.5 8.85786 15.8578 5.5 20 5.5C24.1421 5.5 27.5 8.85786 27.5 13ZM28.013 22.5942C30.7555 20.3013 32.5 16.8544 32.5 13C32.5 6.09644 26.9035 0.5 20 0.5C13.0964 0.5 7.49995 6.09644 7.49995 13C7.49995 16.8544 9.24445 20.3013 11.9869 22.5942C9.70196 23.8432 7.62289 25.616 5.85782 27.8223C4.00065 30.1438 2.52746 32.8998 1.52236 35.9329C1.03041 37.4175 0.655317 38.9543 0.400542 40.5214C-0.0425647 43.2471 2.23853 45.5 4.99995 45.5L35 45.5C37.7614 45.5 40.0425 43.2471 39.5994 40.5214C39.3446 38.9543 38.9695 37.4175 38.4775 35.9329C37.4725 32.8998 35.9993 30.1438 34.1421 27.8223C32.377 25.616 30.298 23.8432 28.013 22.5942ZM33.7313 37.5057C34.0525 38.4749 34.3151 39.476 34.5163 40.5L5.48359 40.5C5.68483 39.476 5.94741 38.4749 6.26857 37.5057C7.10121 34.993 8.30218 32.7708 9.76216 30.9458C11.2211 29.1221 12.8931 27.7504 14.6452 26.8431C16.3909 25.9393 18.2063 25.5 20 25.5C21.7936 25.5 23.609 25.9393 25.3547 26.8431C27.1068 27.7504 28.7788 29.1221 30.2377 30.9458C31.6977 32.7708 32.8987 34.993 33.7313 37.5057ZM35.0068 40.4995C35.0069 40.4996 35.0058 40.4998 35.0035 40.4999L35.0068 40.4995ZM4.99306 40.4995C4.99309 40.4994 4.99423 40.4995 4.99641 40.4999L4.99306 40.4995Z"
      fill={color}
    />
  </svg>
);
export default DefaultProfileIcon;
