import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { getAllWorkByMonth, WorkType } from "../../api/work";


export const workByGroupState = atom<WorkType[][]>({
  key: 'WorkByGroupState',
  default: selector({
    key: 'WorkByGroupState/Default',
    get: async ({ get }) => {
      const allWorks = await getAllWorkByMonth();
      console.log("allWorks", allWorks);

      return allWorks ?? [];
    }
  })
})

export const workByIdState = atomFamily<(WorkType | null), string>({
  key: 'WorkByIdState',
  default: selectorFamily({
    key: 'WorkByIdState/Default',
    get: (workId: string) => ({ get }) => {
      const allWorks = get(workByGroupState);
      console.log("allWorks", allWorks);
      const find = allWorks.flat().find(work => work?.houseWorkId === workId);
      if(!find) return null;
      return find;
    }
  })
})

