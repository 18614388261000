import React from "react";

const ExitGroupIcon:React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <rect
        width="10.667"
        height="1.333"
        x="5.333"
        y="13.333"
        fill="#D1D0D0"
        rx="0.667"
        transform="rotate(-90 5.333 13.333)"
       />
      <rect
        width="4"
        height="1.333"
        x="6.667"
        y="13.333"
        fill="#D1D0D0"
        rx="0.667"
        transform="rotate(180 6.667 13.333)"
       />
      <rect
        width="4"
        height="1.333"
        x="6.667"
        y="4"
        fill="#D1D0D0"
        rx="0.667"
        transform="rotate(180 6.667 4)"
       />
      <path
        fill="#D1D0D0"
        fillRule="evenodd"
        d="M9.757 4.7c.26-.26.683-.26.943 0l2.822 2.822.007.006a.664.664 0 010 .943l-.006.006L10.7 11.3a.667.667 0 11-.943-.943l1.69-1.69H6a.667.667 0 010-1.334h5.448l-1.69-1.69a.667.667 0 010-.943z"
        clipRule="evenodd"
       />
    </svg>
  );
}

export default ExitGroupIcon;
