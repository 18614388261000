import styled from 'styled-components';
import { disabledColor, primaryColor } from '../../theme/colors';

export const CreateItemButtonWrapper = styled.div<{ disabled: boolean }>`
  position: absolute;
  right: 24px;
  bottom: 50px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.disabled ? disabledColor : primaryColor)};
`;
