import React, { useCallback, useMemo, useState } from "react";
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import "./CreateWork.scss"
import { Swiper, SwiperSlide } from "swiper/react";
import { useHistory } from "react-router";
import update from "immutability-helper";
import { MinusIcon, PlusIcon } from "../assets/icons";
import Modal from "../components/Modal/Modal";
import 'swiper/css';
import { loadingState, toastMessagesState } from "../recoil/atoms";
import { createWork, CycleType, DiffType } from "../api/work";
import { getYmd } from "../util/date";
import Loading from "../components/Loading";
import BackPressHeaderWrapper from "../components/BackButtonHeader";
import { isOwnerState } from "../recoil/user/atom";
import { workByGroupState } from "../recoil/work/atoms";
import { currentGroupState } from "../recoil/group/atoms";

const Title: React.FC<{label: string}> = ({label}) => {
  return <span className="text-[20px] font-semibold leading-5 text-zp-black03 mb-2">{label}</span>
}

type CycleValueFormProps = {
  value: string | number;
  setValue: (v: string | number) => void;
}

const WeekValueForm: React.FC<CycleValueFormProps> = ({
  value, setValue
}) => {
  return (
    <div className="w-full flex justify-between items-center grid-cols-7 gap-1">
      {['월', '화', '수', '목', '금', '토', '일'].map(e => {
        return (
          <button
            type="button" key={e}
            className={`
                text-[18px] text-zp-primary
                leading-5 font-semibold
                w-full col-span-1
                w-[40px] h-[40px]
                flex justify-center items-center
                border rounded-full border border-zp-primary
                ${value === e ? 'bg-zp-primary10' : ''}
              `}
            onClick={() => {
              setValue(e);
            }}
          >
            {e}
          </button>
        )
      })}
    </div>
  )
}

const DayValueForm: React.FC<CycleValueFormProps> = ({
  value, setValue,
}) => {
  return (
    <div className="w-full flex justify-between items-center grid-cols-7 gap-1 pl-2">
      <div className="text-base leading-4 font-semibold text-tr-black">
        {value} <span className="text-zp-grey02">일마다</span>
      </div>
      <div className="flex">
        <button
          type="button"
          onClick={() => {
            if (value === 0) return false;
            setValue(Number(value) - 1 )
          }}
          className="
            w-[40px] h-[40px] border rounded-full
            border-zp-primary
            flex justify-center items-center
          "
        >
          <MinusIcon />
        </button>
        <button
          type="button"
          onClick={() => {
            if (value > 14) return false;
            setValue(Number(value) + 1 )
          }}
          className="
            w-[40px] h-[40px] border rounded-full
            border-zp-primary
            flex justify-center items-center ml-2
          "
        >
          <PlusIcon />
        </button>
      </div>
    </div>
  )
}

const MonthValueForm: React.FC<CycleValueFormProps & {setModalOpen: () => void;}> = ({
 value, setValue, setModalOpen
}) => {
return (
  <>
    <div className="w-full flex justify-between items-center grid-cols-7 gap-1 px-2">
      <div className="text-base leading-4 font-semibold text-tr-black">
        {value} <span className="text-zp-grey02">일</span>
      </div>
      <div className="flex">
        <button type="button" onClick={() => {
          console.log('modal open')
          setModalOpen();
        }}
        className="
          flex justify-center items-center
          border-zp-primary text-zp-primary
          text-base leading-4 font-normal
          border border-zp-primary30 rounded-[20px]
          py-3 px-4
        "
        >
          다른 날짜 선택
        </button>
      </div>
    </div>
  </>
  )
}

const CreateWorkPage:React.FC = () => {
  const today = new Date();
  const [loading, setLoading] = useRecoilState(loadingState);
  const navigation = useHistory();
  const setToasts = useSetRecoilState(toastMessagesState)
  const currentGroup = useRecoilValue(currentGroupState)
  const [workName, setWorkName] = useState('');
  const [difficulty, setDifficulty] = useState(1);
  const [isCycle, setIsCycle] = useState(false);
  const [cycleValue, setCycleValue] = useState('');
  const [weekValue, setWeekValue] = useState('')
  const [dayValue, setDayValue] = useState(0)
  const [monthValue, setMonthValue] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [slide, setSlide] = useState(1);

  const errorMessage = useMemo(() => {
    if (!currentGroup?.memberId) return "함께하는 중인 멤버가 없습니다."
    if (!workName) return '할일을 입력해주세요.';
    if (isCycle) {
      if (!cycleValue) return '반복할 단위를 입력해주세요.';
      if (cycleValue === '매일' && !dayValue) return '반복할 일수를 입력해주세요.';
      if (cycleValue === '매주' && !weekValue) return '반복할 요일을 입력해주세요.';
      if (cycleValue === '매달' && !monthValue) return '반복할 일자를 입력해주세요.';
    }
    return '';
  }, [currentGroup, cycleValue, dayValue, isCycle, monthValue, weekValue, workName])

  const [endYear, setEndYear] = useState(1);
  const [endMonth, setEndMonth] = useState(1);
  const isOwner = useRecoilValue(isOwnerState);

  const workCreate = useRecoilCallback(({ set }) => async () => {
    try {
      setLoading(true);
      const cycleToTypes: CycleType[] = ['일_마다', '요일_마다', '매달'];
      const types = ['매일', '매주', '매달'];
      const diffArr = ['LOWER', 'MIDDLE', 'UPPER'];
      const endAt = new Date()
      endAt.setFullYear(endAt.getFullYear() + endYear);
      endAt.setMonth(endAt.getMonth() + endMonth);
      const startCycleDate = new Date();
      startCycleDate.setDate(monthValue);
      if (today.getDate() < monthValue) {
        startCycleDate.setMonth(startCycleDate.getMonth() + 1)
      }
      const addtionals = [dayValue, `${weekValue}요일`, getYmd(startCycleDate)]
      // 2022-10-31
      // 2022-11-05
      const create = await createWork({
        cycleType: cycleValue ? cycleToTypes[types.indexOf(cycleValue)] : null,
        difficulty: diffArr[difficulty - 1] as DiffType,
        endAt: isCycle ? getYmd(endAt) : null,
        isCycle,
        props: isCycle ? { additional: addtionals[types.indexOf(cycleValue)] } : null,
        startAt: getYmd(today),
        title: workName,
        today: getYmd(today),
      });
      // const newWork = {
      //   isOwner,
      //   cycleInfo: isCycle ? {
      //
      //   } : null
      // }
      if(!create) return false;
      set(workByGroupState, (origin) => {
        return update(origin, {
          [isOwner ? 0 : 1] : {
            $push: [...create.map(e => ({ ...e, isOwner }))]
          }
        })
      })
      console.log(create);
      navigation.replace("/home");
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }, [cycleValue, dayValue, difficulty, endMonth, endYear, isCycle, monthValue, setLoading, today, weekValue, workName])

  if (loading) return <Loading />
  return (
    <>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="absolute bottom-0 w-full mx-6 flex flex-col justify-center items-center p-5 rounded-3xl">
          <Swiper
            direction="vertical"
            slidesPerView={3}
            height={164}
            initialSlide={1}
            centeredSlides
            onRealIndexChange={(index) => {
              setSlide(index.realIndex + 1);
            }}
            style={{
              width: '100%',
              height: 164,
              display: 'flex',
              justifyContent: 'cetner',
              alignItems: 'center',
              padding: '1rem 1.25rem'
            }}
            className="
              swiper flex justify-center items-center
              w-full
              rounded-xl bg-zp-white
              py-4 px-5 divide-y
              bg-white h-[164px]
              overflow-y-hidden mb-4 mySwiper
            "
          >
            {new Array(31).fill(0).map((_, i) => {
              return (
                <SwiperSlide
                  className={`
                    h-[44px] flex justify-center items-center
                    bg-white
                    ${slide === i + 1
                    ? 'bg-zp-grey01 text-zp-black03 rounded-lg'
                    : 'text-zp-grey02 border-b border-b-zp-grey02'}
                    ${slide === i + 2 ? 'border-none' : ''}
                  `}
                >
                  {i + 1}일
                </SwiperSlide>
              )
            })}
          </Swiper>
          <button
            className="
              w-full rounded-xl bg-zp-white py-4
              text-xl text-tr-black01 font-semibold leading-5 mx-6
            "
            type="button"
            onClick={() => {
              setMonthValue(slide);
              setIsOpen(false);
            }}
          >
            날짜 선택
          </button>
        </div>
      </Modal>
      <div>
        <BackPressHeaderWrapper canGoBack />
        <div className="w-full max-h-screen overflow-y-scroll px-6 py-9">
          <div className="mb-5">
            <Title label="제목"/>
            <div className="w-full border-b border-b-zp-grey01 bg-zp-white flex justify-between items-center mt-2">
              <input
                placeholder="할일을 입력해주세요"
                className="w-full bg-zp-white text-zp-black03"
                value={workName}
                onChange={(e) => {
                  const newVal = e.target.value;
                  if (newVal.length <= 15) {
                    setWorkName(newVal);
                  } else if (newVal.length > 15) {
                    setWorkName(newVal.slice(0, 15));
                  }
                }}
              />
              <span className="text-sm leading-4 font-normal text-zp-black02">{workName.length}/15</span>
            </div>
          </div>
          <div className="mb-7">
            <Title label="난이도" />
            <div className="mt-2">
              <input
                type="range" min="1" max="3" step="1"
                value={difficulty}
                onChange={(e) => {
                  console.log(e.target.value);
                  setDifficulty(Number(e.target.value))
                }}
                className="
                  .range
                  h-[8px] shadow-inner shadow-[rgba(28, 21, 21, 0.1)] appearance-none w-full bg-zp-primary10 rounded-lg
                  out-range:bg-zp-primary
               "
              />
              <div className="w-full flex justify-between py-1">
                <span className={`
                   difficulty-range-label
                  ${difficulty === 1 ? 
                    'text-zp-primary font-semibold' 
                  : 'text-zp-grey02 text-base'}
                `}>
                    하
                </span>
                <span className={`
                   difficulty-range-label
                  ${difficulty === 2 ? 
                    'text-zp-primary font-semibold' 
                  : 'text-zp-grey02 text-base'}
                `}>
                    중
                </span>
                <span className={`
                   difficulty-range-label
                  ${difficulty === 3 ? 
                    'text-zp-primary font-semibold' 
                  : 'text-zp-grey02 text-base'}
                `}>
                    상
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="w-full flex justify-between items-center">
              <Title label="주기"/>
              <div className="flex justify-between items-center">
                <span className="text-base leading-4 font-normal text-zp-black02">반복</span>
                <button
                  type="button"
                  className={`
                    shadow-[0_0_5px_rgba(28, 21, 21, 0.1)]
                    border border-zp-primary 
                    ml-2 rounded-[18px] w-[48px]
                    flex ${!isCycle ? 'justify-start' : 'justify-end'}
                    ${!isCycle ? 'border-zp-black02' : ''}
                  `}
                  onClick={() => {
                    setIsCycle(!isCycle);
                  }}
                >
                    <div className={`w-[24px] h-[24px] rounded-full bg-zp-primary m-1 ${!isCycle ? 'bg-zp-black02' : ''}`} />
                </button>
              </div>
            </div>
            {isCycle ? (
              <div>
                <div className="w-full grid-cols-3 gap-2 flex justify-between items-center mt-2">
                  {['매일', '매주', '매달'].map(e => {
                    return (
                      <button type="button" key={e}
                              className={`
                        text-[18px] text-zp-primary
                        leading-5 font-semibold
                        w-full col-span-1 py-4
                        flex justify-center items-center
                        border rounded-lg border border-zp-primary
                        ${cycleValue === e ? 'bg-zp-primary10' : ''}
                      `}
                              onClick={() => {
                                setCycleValue(e);
                              }}
                      >
                        {e}
                      </button>
                    )
                  })}
                </div>
                <div
                  className="flex w-full justify-between items-center border-b border-zp-grey02 py-3"
                >
                  {cycleValue === '매일' ? <DayValueForm value={dayValue} setValue={(v) => setDayValue(v as number)} /> : null}
                  {cycleValue === '매주' ? <WeekValueForm value={weekValue} setValue={(v) => setWeekValue(v as string)} /> : null}
                  {cycleValue === '매달'
                    ? <MonthValueForm
                      setModalOpen={() => setIsOpen(true)}
                      value={monthValue}
                      setValue={(v) => setMonthValue(v as number)}
                    /> : null}
                </div>
                <div className="py-3 w-full flex justify-between items-center grid-cols-7 gap-1 pl-2">
                  <div className="text-base leading-4 font-semibold text-zp-black03">
                    {endYear} <span className="text-zp-grey02">년</span> {endMonth} <span className="text-zp-grey02">개월 후 종료</span>
                  </div>
                  <div className="flex">
                    <button
                      type="button"
                      onClick={() => {
                        if (endYear === 0 && endMonth === 0) return false;
                        if (endMonth - 1 === -1) {
                          setEndMonth(11);
                          setEndYear(endYear - 1)
                          return false;
                        }
                        setEndMonth(endMonth - 1);
                      }}
                      className="
                      w-[40px] h-[40px] border rounded-full
                      border-zp-primary
                      flex justify-center items-center
                    "
                    >
                      <MinusIcon />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        if (endYear === 2) return false;
                        if (endMonth + 1 === 12) {
                          setEndMonth(0);
                          setEndYear(endYear + 1)
                          return false;
                        }
                        setEndMonth(endMonth + 1);
                      }}
                      className="
                      w-[40px] h-[40px] border rounded-full
                      border-zp-primary
                      flex justify-center items-center ml-2
                    "
                    >
                      <PlusIcon />
                    </button>
                  </div>
                </div>
              </div>
            ): null}
          </div>
        </div>
          <div
            className={`
              absolute bottom-0 w-full flex justify-center items-center mb-4
            `}>
            <button
              type="button"
              className={`
                text-xl font-semibold leading-6 w-full bg-zp-primary mx-6 py-4 rounded-2xl text-zp-white
                ${errorMessage ? 'bg-zp-black02' : ''}
              `}
              onClick={() => {
                if (errorMessage) {
                  setToasts((origin) => origin.concat(errorMessage))
                  return false;
                }
                workCreate();
              }}
              >
              추가하기
            </button>
          </div>
      </div>
    </>
  )
}

export default CreateWorkPage;
