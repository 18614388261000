import React from "react";

const FailWorkIcon:React.FC<{ color?: string }> = ({ color = '#D1D0D0' }) =>  {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M9.536 10.95a1 1 0 001.414-1.414L7.414 6l3.536-3.535A1 1 0 009.536 1.05L6 4.586 2.464 1.05A1 1 0 101.05 2.464L4.586 6 1.05 9.536a1 1 0 001.414 1.414L6 7.414l3.536 3.536z"
        clipRule="evenodd"
       />
    </svg>
  );
}

export default FailWorkIcon;
