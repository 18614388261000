import React from "react";

const EmptyChartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="168"
      fill="none"
      viewBox="0 0 140 168"
    >
      <path
        fill="#E8E8E8"
        d="M72.97 64.57a4.2 4.2 0 010 5.94l-53.457 53.457a4.2 4.2 0 01-5.94-5.94L67.03 64.57a4.2 4.2 0 015.94 0z"
       />
      <path
        fill="#E8E8E8"
        d="M67.03 64.57a4.2 4.2 0 015.94 0l53.457 53.457a4.2 4.2 0 01-5.94 5.94L67.03 70.51a4.2 4.2 0 010-5.94zM63 110.6a4.2 4.2 0 11-8.4 0 4.2 4.2 0 018.4 0zM85.4 110.6a4.2 4.2 0 11-8.4 0 4.2 4.2 0 018.4 0z"
       />
      <path
        fill="#E8E8E8"
        fillRule="evenodd"
        d="M70 131.6a7 7 0 107 7 4.2 4.2 0 018.4 0c0 8.505-6.895 15.4-15.4 15.4s-15.4-6.895-15.4-15.4 6.895-15.4 15.4-15.4a4.2 4.2 0 010 8.4zM61.6 14c-9.278 0-16.8 7.522-16.8 16.8s7.522 16.8 16.8 16.8h2.46l3.96 3.96a2.8 2.8 0 003.96 0l3.96-3.96h2.46c9.278 0 16.8-7.522 16.8-16.8S87.678 14 78.4 14H61.6zM70 32.2a4.2 4.2 0 10-4.2-4.2 2.1 2.1 0 11-4.2 0 8.4 8.4 0 1110.5 8.135v.265a2.1 2.1 0 11-4.2 0v-2.1c0-1.16.94-2.1 2.1-2.1zm0 8.4a2.1 2.1 0 100 4.2 2.1 2.1 0 000-4.2z"
        clipRule="evenodd"
       />
    </svg>
  );
}

export default EmptyChartIcon;
