import { atom, selector } from "recoil";
import { getAnalyzationData } from "../../api/analysis";


export const analyzationByGroupState = atom<any[]>({
  key: 'AnalyzationByGroupState',
  default : selector({
    key: "AnalyzationByGroupState/Default",
    get: async ({ get }) => {
      const analysisData = await getAnalyzationData();
      return (analysisData ?? []) as []
    }
  })
})
