import React from 'react';
import { IconProps } from './ChartIcon';

const AddWorkIcon: React.FC<IconProps> = ({ width = 24, height = 24, color = 'white' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M10.5 22.5a1.5 1.5 0 003 0v-9h9a1.5 1.5 0 000-3h-9v-9a1.5 1.5 0 00-3 0v9h-9a1.5 1.5 0 000 3h9v9z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default AddWorkIcon;
