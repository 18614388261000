import styled from 'styled-components';

export const Container = styled.div`
  height: fit-content;
  bottom: 0;
  position: fixed;
  left: 0;
  width: 100%;
  display: flex;
  background-color: white;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 16px;
  padding-bottom: 28px;
  padding-left: 42px;
  z-index: 1;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  box-shadow: 0 -1vh 2vh rgba(0, 0, 0, 0.06);
`;
