import React from "react";

const BackButtonIcon:React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="17"
      fill="none"
      viewBox="0 0 10 17"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M2.4 8.086l6.378 6.378a1 1 0 11-1.414 1.415L.293 8.808A.997.997 0 010 8.086a.997.997 0 01.293-.722L7.364.293a1 1 0 111.414 1.414L2.4 8.086z"
        clipRule="evenodd"
       />
    </svg>
  );
}

export default BackButtonIcon;
