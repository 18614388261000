import React, { useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import BackButtonHeader from "../components/BackButtonHeader";
import { HeartEmptyIcon, FromOldIcon, XsProfileIcon, OtherMenuIcon } from "../assets/icons";
import DateTimeSelector from "../components/DateTimeSelector";
import NewHeartItem from "../components/NewHeartItem";
import {
  LetterType,
  myLetterByIdState,
  myLetterIdsState,
  myLettersState,
  unReadLetterState
} from "../recoil/letter/atoms";
import { isOwnerState } from "../recoil/user/atom";
import { getShortMd } from "../util/date";
import { getAllWorkByMonth } from "../api/work";
import { loadingState } from "../recoil/atoms";
import Loading from "../components/Loading";
import { getAllLetter, getUnreadLetter } from "../api/letter";
import { currentGroupState } from "../recoil/group/atoms";

const EmptyHeart = () => {
  return (
    <div className="flex w-full h-full flex flex-col justify-center items-center">
      <HeartEmptyIcon />
      <span className="mt-6 text-base leading-5 font-normal text-zp-grey02 text-center">
        아직 속마음이 없어요
      </span>
      <span className="text-base font-semibold leading-5 text-zp-grey02 mt-3 text-center">
        아래의 작성 버튼을 눌러<br />
        상대방에게 속마음을 전해보세요!
      </span>
      <div className="h-[80px] w-full" />
    </div>
  )
}

type HeartItemProps = {
  letterId: string;
}

const HeartItem: React.FC<HeartItemProps> = ({letterId}) => {
  const letter = useRecoilValue(myLetterByIdState(letterId));

  const ownerLetter = useMemo(() => {
    const find = letter?.find(e => e.from.memberAuthorityRoles === '[OWNER]');
    return find ?? null;
  }, [letter]);

  const memberLetter = useMemo(() => {
    const find = letter?.find(e => e.from.memberAuthorityRoles === '[DEFAULT]');
    return find ?? null;
  }, [letter])
  return (
    <div className="w-full flex flex-col p-5 shadow-[0_0_5px_0_#1C151532] rounded-2xl mb-3">
      <div className="w-full flex items-center mb-4 border-b pb-5">
        <span className="text-base leading-4 font-semibold mr-2">{getShortMd(new Date(ownerLetter?.createAt ?? ''))}</span>
      </div>
      <div className="border-b mb-4">
        <div className="flex items-center">
          <div className="flex mr-2 justify-center items-center w-[24px] h-[24px] bg-zp-primary rounded-full">
            <XsProfileIcon />
          </div>
          <span className="text-base leading-4 font-semibold text-zp-black03 mr-2">{ownerLetter?.kind ?? ''}</span>
        </div>
        <div className="py-2 text-zp-black03">
          {ownerLetter?.content ?? ''}
        </div>
      </div>
      <div>
        <div className="flex items-center">
          <div className="flex mr-2 justify-center items-center w-[24px] h-[24px] bg-zp-secondary rounded-full">
          <XsProfileIcon />
        </div>
        <span className="text-base leading-4 font-semibold text-zp-black03 mr-2">{memberLetter?.kind ?? ''}</span>
        </div>
        <div className="py-2 text-zp-black03">
          {memberLetter?.content ?? ''}
        </div>
      </div>
    </div>
    )
}

const HeartPage: React.FC = () => {
  const navigation = useHistory()
  const letterIds = useRecoilValue(myLetterIdsState);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [letters, setLetters] = useRecoilState(myLettersState);
  const [unReadLetters, setUnReadLetters] = useRecoilState(unReadLetterState);
  const isOwner = useRecoilValue(isOwnerState);
  const currentGroup = useRecoilValue(currentGroupState);
  const decreaseDate = () => {
    setCurrentDate((origin) => {
      const date = new Date(origin);
      date.setMonth(origin.getMonth() - 1);
      return date;
    })
  }
  const increaseDate = () => {
    setCurrentDate((origin) => {
      const date = new Date(origin);
      date.setMonth(origin.getMonth() + 1);
      return date;
    })
  }
  const [loading, setLoading] = useRecoilState(loadingState);
  const refreshLetter = async () => {
    try {
      setLoading(true)

      const userId = isOwner ? currentGroup?.memberId : currentGroup?.ownerId;
      setLetters(await getAllLetter() as LetterType[]);
      setUnReadLetters(await getUnreadLetter(userId ?? '') as LetterType[]);
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  if (loading) return <Loading />
  return (
    <div className="w-full h-full flex-1">
      <BackButtonHeader canGoBack={false} otherButton>
        <button type="button" onClick={() => {
          navigation.push('/other-menu')
        }}>
          <OtherMenuIcon />
        </button>
      </BackButtonHeader>
      <div className="w-full h-full max-h-screen overflow-y-scroll">
        {unReadLetters?.length ? (
          <div className="flex flex-col justify-between py-9 px-6 border-b border-b-8 border-b-zp-grey01">
            <span className="text-base leading-4 font-semibold text-zp-black03 mb-4">새로운 속마음</span>
            {unReadLetters?.map((e, i) => {
              return <NewHeartItem key={i} letter={e} isOwner={isOwner} />;
            })}
          </div>
        ): null}
        <div className="w-full py-9 px-6">
          <div className="flex justify-between items-center">
            <DateTimeSelector
              selectMode="month"
              date={currentDate}
              prevDate={decreaseDate}
              nextDate={increaseDate}
            />
            <div>
              <button
                onClick={refreshLetter}
                type="button"
                className="border py-1 px-2 text-zp-grey02 border-zp-grey02 text-xs rounded-full"
              >
                새로고침
              </button>
            </div>
          </div>
          <div className="w-full py-6">
            {letterIds?.length ? (
              letterIds.map((letter, i) => {
                return <HeartItem key={i} letterId={letter} />
              })
            ): <EmptyHeart />}
          </div>
        </div>
        <div className="h-[120px] w-full" />
      </div>
    </div>
  );
};

export default HeartPage;
