import { atom, selector } from 'recoil';
import axios from 'axios';
import { API_URL } from '../../config/app';

type MemberType = {
  memberEmail: string;
  memberId: string;
  memberLoginRole: string;
  memberName: string;
  memberProfilePicture: string;
  memberRole: string;
};

export const userInputState = atom({
  key: 'UserInput',
  default: {},
});

export const isOwnerState = atom({
  key: "IsOwnerState",
  default: selector({
    key: "IsOwnerState/Default",
    get: ({get }) => {
      const user = get(userState);
      return user?.memberRole === 'OWNER';
    }
  })
})

export const userState = atom<MemberType | null>({
  key: 'User',
  default: selector({
    key: 'User/Default',
    get: async () => {
      try {
        const userToken = sessionStorage.getItem('accessToken');
        console.debug(userToken, 'userToken userState');
        if (!userToken) return null;
        const profile = await axios.get(`${API_URL}/util/login-member`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        console.log("profile", profile.data.data);
        return profile.data.data as MemberType;
      } catch (e) {
        console.debug('GET PROFILE ERROR', e);
        sessionStorage.removeItem('accessToken');
        return null;
      }
    },
  }),
});
