import React from 'react';
import { useHistory } from "react-router";
import { XsProfileIcon } from "../../assets/icons";
import { LetterType } from "../../recoil/letter/atoms";
import { getDate, getShortMd } from "../../util/date";

type NewHeartItemProps = {
  letter: LetterType;
  isOwner: boolean;
}

const NewHeartItem:React.FC<NewHeartItemProps> = ({letter, isOwner}) => {
  const navigation = useHistory();
  const createdAt = getShortMd(new Date(letter?.createAt ?? ''))
  return (
     <div className="w-full flex flex-col p-5 shadow-[0_0_5px_0_#1C151532] rounded-2xl mb-3">
       <div className="w-full flex items-center mb-4">
         <div className={`flex mr-2 justify-center items-center w-[24px] h-[24px] ${isOwner ? 'bg-zp-secondary' : 'bg-zp-primary'}  rounded-full`}>
           <XsProfileIcon />
         </div>
         <span className="text-base text-zp-black03 leading-4 font-semibold mr-2">
           {createdAt}
         </span>
         <span className="text-zp-grey01 text-base mr-2"> | </span>
         <span className="text-base leading-4 text-zp-black03 font-semibold mr-2">{letter?.kind ?? ''}</span>
       </div>
       <button
         type="button"
         className="
          flex justify-center items-center border rounded-2xl border-zp-primary
          py-4 text-lg leading-5 font-semibold text-zp-primary
        "
         onClick={() => {
           navigation.push(`/reply-heart/${letter?.heartId ?? ''}`)
         }}
       >
         속마음 보내고 확인하기
       </button>
     </div>
   )
}

export default NewHeartItem;
