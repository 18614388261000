import axios from "axios";
import { API_URL } from "../config/app";
import { LetterType } from "../recoil/letter/atoms";


export const getUnreadLetter = async (userId: string) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
    }
    axios.get(`${API_URL}/letter/not-read?userId=${userId}`,{ headers })
      .then(res => {
        console.log(res);
        resolve((res.data.list ?? []) as LetterType[])
      })
      .catch(err => {
        console.log(err);
        reject();
      })
  })
}

export const getAllLetter = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
    }
    axios.post(`${API_URL}/letter/view-all-letter`, {}, { headers })
      .then(res => {
        console.log(res);
        resolve((res.data.list ?? []) as LetterType[])
      })
      .catch(err => {
        console.log(err);
        reject();
      })
  })
}

type CreateLetterInput = {
  content : string;
  from: string;
  heartType: string;
}

export const createLetter = async ({
  content,
  from,
  heartType,
}: CreateLetterInput) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
    }
    console.log("to:", from);
    axios.post(`${API_URL}/letter/write-first`, {
      content,
      from,
      heartType,
    }, { headers })
      .then(res => {
        resolve(res)
        console.log(res);
      })
      .catch(err => {
        console.log(err);
        reject();
      })
  })
}


type ReplyLetterInput = {
  content : string;
  heartId: string;
  heartType: string;
}

export const replyLetter = async ({
  heartId,
  content,
  heartType
}:ReplyLetterInput) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
    }
    axios.post(`${API_URL}/letter/write-second?heartId=${heartId}`, {
      content,
      heartType,
    }, { headers })
      .then(res => {
        resolve(res)
        console.log(res);
      })
      .catch(err => {
        console.log(err);
        reject();
      })
  })
}
