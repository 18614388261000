import React from "react";

const ManyWorkIcon:React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#FF5372"
        fillRule="evenodd"
        d="M18 30c6.627 0 12-5.373 12-12S24.627 6 18 6 6 11.373 6 18s5.373 12 12 12zm0 3c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C9.716 3 3 9.716 3 18c0 8.284 6.716 15 15 15z"
        clipRule="evenodd"
       />
      <path
        fill="#FF5372"
        fillRule="evenodd"
        d="M12 25.5a1.5 1.5 0 001.5-1.5 4.501 4.501 0 018.657-1.722c.227.546.343 1.131.343 1.722a1.5 1.5 0 003 0 7.5 7.5 0 00-15 0 1.5 1.5 0 001.5 1.5z"
        clipRule="evenodd"
       />
      <path
        fill="#FF5372"
        d="M16.5 13.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM22.5 13.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
       />
    </svg>
  );
}

export default ManyWorkIcon;
