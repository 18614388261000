import React, { PropsWithChildren } from 'react';
import './Modal.scss';

type ModalProps = {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
} & PropsWithChildren;

const Modal: React.FC<ModalProps> = ({ isOpen, setIsOpen, children }) => {
  if (!isOpen) return null;
  return (
    <>
      <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-[3]">
        {children}
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        role="button"
        className="background"
        tabIndex={0}
        onClick={() => {
          setIsOpen(false);
        }}
      >
        background
      </div>
    </>
  );
};

export default Modal;
