import React from 'react';
import { IconProps } from './ChartIcon';

const LogoIcon: React.FC<IconProps> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <path
        fill="#FF90A0"
        d="M54.167 37.5h-8.334a4.167 4.167 0 000 8.333h8.334a4.167 4.167 0 100-8.333z"
      />
      <path
        fill="#FF90A0"
        fillRule="evenodd"
        d="M52.946 14.435a4.167 4.167 0 00-5.892 0l-.032.032L11.699 49.79a4.167 4.167 0 005.893 5.892L50 23.274l32.41 32.409a4.167 4.167 0 005.892-5.893L52.946 14.435z"
        clipRule="evenodd"
      />
      <path
        fill="#FF90A0"
        fillRule="evenodd"
        d="M33.333 62.5v16.667h33.334V62.5H33.333zm-4.166-8.333A4.167 4.167 0 0025 58.333v25a4.167 4.167 0 004.167 4.167h41.666A4.167 4.167 0 0075 83.333v-25a4.167 4.167 0 00-4.167-4.166H29.167z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default LogoIcon;
