import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useHistory, useParams } from "react-router";
import { userState } from "../recoil/user/atom";
import { loadingState, toastMessagesState } from "../recoil/atoms";
import { createGroup, joinGroup } from "../api/group";
import { currentGroupState, groupByIdState } from "../recoil/group/atoms";
import Loading from "../components/Loading";
import { ProfileForm } from "../components";
import { ProfileIcon } from "../assets/icons";
import { ProfileRemoveModal } from "../containers/GroupContainer";

const JoinPage: React.FC = () => {
  const { linkId } = useParams<{ linkId: string }>();
  const [user] = useRecoilState(userState);
  const navigation = useHistory();
  const [loading, setLoading] = useRecoilState(loadingState)
  console.log("user", user);
  const [profileImage, setProfileImage] = useState(user?.memberProfilePicture ?? '');
  const [isProfileRemoveOpen, setProfileRemoveOpen] = useState(false);
  const [name, setName] = useState((user?.memberName ?? '').slice(0, 6));
  const currentGroup = useRecoilValue(currentGroupState);
  console.log(currentGroup);
  const join = useRecoilCallback(({ set }) => async () => {
    try {
      setLoading(true);
      const token = sessionStorage.getItem('accessToken');
      if(!token) {
        console.log("not token", token);
        return false;
      }
      const linkId = sessionStorage.getItem('currentGroupLinkId');
      const groupData = await joinGroup(linkId ?? '', name);
      if (!groupData) return false;
      set(currentGroupState, {
        ...groupData
      });
      console.log(groupData);
      sessionStorage.setItem('currentGroupLinkId', groupData?.linkId);
      navigation.replace("/home")
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [name, navigation, setLoading]);

  useEffect(() => {
    if (!user) {
      sessionStorage.setItem('currentGroupLinkId', linkId);
      navigation.replace("/login")
    }
  }, [linkId, navigation, user])

  if (loading) return <Loading />
  return (
    <>
      <ProfileRemoveModal
        isOpen={isProfileRemoveOpen}
        setOpen={setProfileRemoveOpen}
        removeProfile={() => {
          setProfileImage('');
          setProfileRemoveOpen(false);
        }}
      />
      <div className="flex flex-col w-full h-full justify-between items-center">
        <div className="empty-header" />
        <ProfileForm
          name={name}
          setName={setName}
          onPressProfile={() => {
            setProfileRemoveOpen(true);
          }}
          profileImage={profileImage}
        />
          <div className="w-full flex flex-col items-center mb-9 px-6">
            <div className="flex w-full justify-center items-center mb-4">
              <ProfileIcon />
              <span className="font-normal text-base leading-4 text-zp-primary ml-2">
                <strong className="font-semibold">{currentGroup?.ownerName ?? ''}</strong> 님의 {currentGroup?.groupName}
              </span>
            </div>
            <button
              type="button"
              onClick={join}
              className="
                w-full flex justify-center items-center
                rounded-2xl bg-zp-primary py-4
                font-semibold leading-6 text-xl text-white
              "
            >
              집안일 참여하기
            </button>
          </div>
      </div>
    </>
  );
}

export default JoinPage;
