import React from 'react';
import { IconProps } from './ChartIcon';

const WriteHeartIcon: React.FC<IconProps> = ({ width = 29, height = 29, color = '#fff' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 29 29"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M20.213 4.243l4.243 4.242-2.121 2.122-4.243-4.243 2.121-2.121zm-5.32 1.078L2.195 18.018l-.013.013a1.493 1.493 0 00-.426.861L.697 26.304a1.5 1.5 0 001.697 1.697l7.395-1.056c.321-.04.632-.182.878-.428l16.97-16.971a1.495 1.495 0 000-2.122L21.275 1.06a1.5 1.5 0 00-2.121 0l-.015.015-4.21 4.21-.018.018-.017.018zM6.425 24.395l-2.122-2.121-.353 2.474 2.475-.353zm3.182-1.06l-4.243-4.243L15.971 8.485l4.242 4.243L9.607 23.334z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default WriteHeartIcon;
