import axios from "axios";
import { API_URL } from "../config/app";
import { getYmd } from "../util/date";


export const getAnalyzationData = async () => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
    }
    const today = getYmd(new Date())
    axios.post(`${API_URL}/analysis/create?today=${today}`, {}, { headers }).then((res) => {
      console.log(res);
      const { list } = res.data;
      console.log(list);
      const sort = list?.sort((a: any, b: any) => {
        return a?.memberInfo === "[OWNER]" ? -1 : 1;
      })

      console.log("sort", sort);
      const data = list?.map((item: any) => {
        const { groupInfo, memberInfo, ...values} = item;
        return values;
      })
      resolve(data);
    })
  })
}
