import React from "react";

const ArrowIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="12"
      fill="none"
      viewBox="0 0 7 12"
    >
      <path
        fill="#777373"
        fillRule="evenodd"
        d="M2.266 6.057L6.52 10.31a.667.667 0 11-.943.942L.862 6.538a.665.665 0 01-.195-.48.665.665 0 01.195-.482L5.576.862a.667.667 0 01.943.943L2.266 6.057z"
        clipRule="evenodd"
       />
    </svg>
  );
}

export default ArrowIcon;
