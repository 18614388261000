import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { toastMessagesState } from "./recoil/atoms";


type ToastItemProps = {
  message: string;
  removeToast: () => void;
}

const ToastItem: React.FC<ToastItemProps> = ({ message, removeToast }) => {
  return (
    <div
      className="
        max-w-[80%] bg-[rgba(0,0,0,0.8)] flex items-center py-0.5 px-2 rounded-full text-center text-zp-grey01 text-sm z-[99999999] m-1
      "
    >
      {message}
      <button className="px-1" type="button" onClick={removeToast}>
        ✕
      </button>
    </div>
  )
}

const ToastProvider: React.FC = () => {
  const [toasts, setToasts] = useRecoilState(toastMessagesState);

  useEffect(() => {
    if (!toasts.length) return;
    const timeout = setTimeout(() => {
      setToasts((prev) => {
        const origin = [...prev];
        origin.shift();
        return origin;
      });
    }, 1500);

    return () => clearTimeout(timeout);
  }, [setToasts, toasts]);

  const removeToast = (value: string) => {
    console.log("removetoast");
    setToasts(toasts.filter((e) => e !== value))
  }

  return (
    <div className="absolute w-full h-full bg-[rgba(0,0,0,0)] flex flex-col justify-end items-center py-16">
      {toasts.map((e, i) => {
        return <ToastItem key={i} message={e} removeToast={() => {
          removeToast(e)
        }} />
      })}
    </div>
  )
}

export default ToastProvider;
