import React from 'react';
import { GoogleLoginButton, KakaoLoginButton, LoginButtonsContainer } from './S';
import { GoogleIcon, KakaoIcon } from '../../assets/icons';

type LoginButtonsProps = {
  kakaoLogin: string;
  googleLogin: string;
};

const LoginButtons: React.FC<LoginButtonsProps> = ({ kakaoLogin, googleLogin }) => {
  return (
    <LoginButtonsContainer>
      <KakaoLoginButton href={kakaoLogin}>
        <KakaoIcon />
        <span>카카오 로그인</span>
      </KakaoLoginButton>
      <GoogleLoginButton href={googleLogin}>
        <div className="icon-wrapper">
          <GoogleIcon />
        </div>
        <span>Google 계정으로 로그인</span>
      </GoogleLoginButton>
    </LoginButtonsContainer>
  );
};

export default LoginButtons;
