import React, { useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useHistory } from "react-router";
import DateTimeSelector from "../../components/DateTimeSelector";
import { getYmd, pad } from "../../util/date";
import { ArrowIcon, OtherMenuIcon ,
} from "../../assets/icons";
import { currentGroupState } from "../../recoil/group/atoms";
import BackButtonHeader from "../../components/BackButtonHeader";
import { WorkList } from "../../components";
import { workByGroupState } from "../../recoil/work/atoms";
import { loadingState } from "../../recoil/atoms";
import { getAllWorkByMonth } from "../../api/work";
import Loading from "../../components/Loading";
import { EmptyWorkIllust } from "../../assets/illusts";

type WorkCalendarProps = {
  currentDate: Date;
  selectedDate: Date;
  onDayPress: (date: Date) => void;
}

const WorkCalendar: React.FC<WorkCalendarProps> = ({
  currentDate,
  selectedDate,
  onDayPress
}) => {
  const works = useRecoilValue(workByGroupState);
  const [toggleDown, setToggleDown] = useState(true);
  const monthdays: number[][] = useMemo(() => {
    const date = new Date(currentDate);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1).getUTCDate();
    const daysOfWeek = new Date(`${date.getFullYear()}-${pad(date.getMonth() + 1)}-01`).getDay();
    const monthArr = new Array(6).fill(new Array(7).fill(0));
    return monthArr.map((e, i) => {
      return e.map((v: number, vi: number) => {
        const index = 7 * i + vi;
        if (daysOfWeek > index) return undefined;
        if (lastDay < index - daysOfWeek + 1) return undefined;
        return index - daysOfWeek + 1;
      });
    });
  }, [currentDate]);
  return (
    <div className="w-full flex flex-col">
      <div className={`transition-all ${toggleDown ? 'h-fit' : 'h-0'} overflow-hidden`}>
        <div className="w-full flex justify-between items-center">
          {['일', '월', '화', '수', '목', '금', '토'].map((weekday) => {
            return (
              <div
                className={`w-10 h-10 text-center flex justify-center items-center text-base font-bold leading-8 text-zp-grey02
                  ${weekday === '일' ? 'text-zp-red01' : ''}
                  ${weekday === '토' ? 'text-zp-blue01' : ''}
                `}
              >
                {weekday}
              </div>
            );
          })}
        </div>
        {monthdays.map((week, index) => {
          return (
            <div key={index} className="w-full flex justify-between items-center mb-1.5">
              {week.map((day, dayIndex) => {
                const isSelected =
                  selectedDate.getFullYear() === currentDate.getFullYear() &&
                  selectedDate.getMonth() === currentDate.getMonth() &&
                  selectedDate.getDate() === day;
                const current = new Date(currentDate);
                current.setDate(day)
                const isToday = getYmd(new Date()) === getYmd(current);
                const isAlreadyWorks = works.map(ws => {
                  const find = ws.find(work => work.today === getYmd(current));
                  return !!find;
                });
                const todayAllDone = works.map(ws => {
                  return ws.filter(w => w.today === getYmd(current))
                }).map(ws => {
                  return ws.every(w => w.houseWorkStatusType === "COMPLETED")
                })
                console.log(isAlreadyWorks);
                return (
                  <button
                    type="button"
                    onClick={() => {
                      if (!day) return false;
                      const newDate = new Date(currentDate);
                      newDate.setDate(day);
                      onDayPress(newDate);
                    }}
                    key={dayIndex}
                    className={`
                      w-10 h-10 text-center flex flex-col justify-center items-center rounded-full text-sm leading-6 font-normal text-zp-black01
                      ${dayIndex === 0 ? 'text-tr-red01' : ''}
                      ${dayIndex === week.length - 1 ? 'text-tr-primary' : ''}
                      ${isSelected ? 'border border-zp-black02 rounded-xl bg-zp-white' : ''}
                      ${isToday ? "bg-zp-primary30 text-zp-white" : ""}
                    `}
                  >
                    {day}
                    {day ? (
                      <div className="flex">
                        {isAlreadyWorks.map((v, i) => {
                          if (!v) return null;
                          return (
                            <div className={`
                                w-1 h-1 ${
                                i ? todayAllDone[i] ? "bg-zp-secondary" : "bg-zp-secondary30" : todayAllDone[i] ? "bg-zp-primary" : "bg-zp-primary30"
                                } rounded-full mx-[1px]
                              `}
                            />
                          )
                        })}
                      </div>
                    ): null}
                  </button>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="w-full border-t border-double">
        <div className="w-full border-t border-double my-1 flex justify-center items-center">
          <button
            type="button"
            className={`
              py-2 px-2
              ${toggleDown ? 'rotate-90' : '-rotate-90'}
            `}
            onClick={() => {
              setToggleDown(!toggleDown);
            }}
          >
            <ArrowIcon />
          </button>
        </div>
      </div>
    </div>
  )
}

const HomeContainer: React.FC = () => {
  const navigation = useHistory()
  const [works, setWorks] = useRecoilState(workByGroupState);
  console.log(works);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());
  const [dateMode, setDateMode] = useState("week");
  const [loading, setLoading] = useRecoilState(loadingState);

  const [group] = useRecoilState(currentGroupState);

  const refreshWork = async () => {
    try {
      setLoading(true)
      setWorks(await getAllWorkByMonth());
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }
  console.log("change", currentDate)
  const decreaseDate = () => {
    setCurrentDate((origin) => {
      const date = new Date(origin);
      date.setMonth(origin.getMonth() - 1);
      return date;
    })
  }
  const increaseDate = () => {
    setCurrentDate((origin) => {
      const date = new Date(origin);
      date.setMonth(origin.getMonth() + 1);
      return date;
    })
  }
  const dayByWorks = useMemo(() => {
    return works.map(workList => {
      return workList.filter(work => {
        const createdAt = getYmd(new Date(work.today))
        const selectDate = getYmd(selectedDate);
        return createdAt === selectDate;
      })
    })
  }, [selectedDate, works])
  if (loading) return <Loading />
  return (
    <>
      <div>
        <BackButtonHeader canGoBack={false} otherButton>
          <button type="button" onClick={() => {
            navigation.push('/other-menu')
          }}>
            <OtherMenuIcon />
          </button>
        </BackButtonHeader>
        <div className="w-full max-h-screen overflow-y-scroll">
          <div className="bg-zp-white pt-6">
            <div className="w-full flex justify-between items-center px-6">
              <DateTimeSelector
                selectMode={dateMode}
                date={currentDate}
                prevDate={decreaseDate}
                nextDate={increaseDate}
              />
              <div className="flex">
                <button
                  onClick={refreshWork}
                  type="button"
                  className="border py-1 px-2 text-zp-grey02 border-zp-grey02 text-xs rounded-full"
                >
                  새로고침
                </button>
              </div>
            </div>
            <div className="w-full py-2.5 px-5">
              <WorkCalendar
                currentDate={currentDate}
                onDayPress={(date) => {
                  setSelectedDate(date)
                  console.log('selectedDate')
                }}
                selectedDate={selectedDate}
              />
            </div>
          </div>
          <div className="px-6">
            {dayByWorks.every(e => e.length === 0) ? (
              <div className="w-full flex flex-col items-center py-[3.75rem]">
                <EmptyWorkIllust />
                <span className="text-base font-normal leading-5 mb-3 text-zp-grey02 mt-6">아직 할일이 없어요</span>
                <span className="w-full text-base leading-5 font-semibold text-zp-grey02 text-center">아래의 더하기 버튼을 눌러<br />추가해보세요!</span>
              </div>
            ): (
              <>
                <WorkList works={dayByWorks[0]} isOwner />
                <WorkList works={dayByWorks[1]} isOwner={false} />
              </>
            )}
          </div>
          <div className="h-[160px] w-full" />
        </div>
      </div>
    </>
  )
}

export default HomeContainer;
