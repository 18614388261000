export function pad(str: string | number): string {
  let result = '';
  if (typeof str === 'number') result = str.toString();
  if (result.length === 1) return `0${result}`;
  return result;
}

export function getDayOfWeek(date: Date): string {
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  return week[date.getDay()];
}

export function getYmd(date: Date, word = '-'): string {
  return `${date.getFullYear()}${word}${pad(date.getMonth() + 1)}${word}${pad(date.getDate())}`;
}

export function getShortMd(date: Date): string {
  return `${pad(date.getMonth() + 1)}월 ${pad(date.getDate())}일`;
}

export function timestampToMs(value: number): string {
  const seconds = (value % 60).toString();

  return `${Math.floor(value / 60).toString()}:${(seconds.length === 1 ? '0' : '') + seconds}`;
}

export function timestampToHi(source: number): string {
  const date = new Date(source);
  return `${pad(date.getHours())}:${pad(date.getMinutes())}`;
}

export function timestampToPaymentAt(source: number, word = '-'): string {
  return `${getYmd(new Date(source), word)} ${timestampToHi(source)}`;
}
export function timestampToYmd(source: number, word = '-'): string {
  return `${getYmd(new Date(source), word)}`;
}

export function timestampToLongDate(source: number, word = '.'): string {
  const date = new Date(source);
  return `${getYmd(date, word)} ${getDayOfWeek(date)}요일`;
}

export function getRangeTime(startDate: Date, endDate: Date): string {
  return `${startDate.getHours()}:${startDate.getMinutes()} ~${endDate.getHours()}:${endDate.getMinutes()}`;
}

export function getYmKR(date: Date): string {
  return `${date.getFullYear()}년 ${pad(date.getMonth() + 1)}월`;
}

export function getYmdKR(date: Date): string {
  return `${date.getFullYear()}년${pad(date.getMonth() + 1)}월${pad(date.getDate())}일`;
}

/* TODO 분단위 표기 처리 및 UI 깨지는 부분이 없는지 확인 필요. */
export function getHiKR(date: Date): string {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  let AMorPM = '오전';
  if (hours > 11) AMorPM = '오후';
  if (hours > 12) hours -= 12;
  return `${AMorPM} ${hours}시 ${minutes ? `${minutes}분` : ''}`;
}

export function getDateFormat(source: Date | string | number): string {
  const date: Date = getDate(source);
  return `${getYmd(date, '.')} (${getDayOfWeek(date)})`;
}

export function getFormatPureDate(source: Date | string | number): string {
  const date: Date = new Date(source);
  return `${getYmd(date, '.')} (${getDayOfWeek(date)})`;
}

export function getSimpleHiKR(date: Date, eng: boolean): string {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  let AMorPM = eng ? 'AM' : '오전';
  if (hours > 11) AMorPM = eng ? 'PM' : '오후';
  if (hours > 12) hours -= 12;
  return `${hours}:${pad(minutes)} ${AMorPM}`;
}
export function getTimeFormat(source: Date | string | number): string {
  const date: Date = getDate(source);
  // return `${getHiKR(date)} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
  return `${getHiKR(date)}`;
}
// arrivalAt

export function getHiAMPM(source: Date | string | number, eng = false): string {
  const date: Date = getDate(source);

  let hours = date.getHours();
  const minutes = date.getMinutes();
  let AMorPM = eng ? 'AM' : '오전';
  if (hours > 11) AMorPM = eng ? 'PM' : '오후';
  if (hours > 12) hours -= 12;
  return `${hours}:${pad(minutes)} ${AMorPM}`;
}


export const getHiKRWithAMorPM = (timestamp: number): string => {
  const date = new Date(timestamp);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  let AMorPM = '오전';
  if (hours > 11) AMorPM = '오후';
  if (hours > 12) hours -= 12;
  return `${AMorPM} ${hours}:${pad(minutes)}`;
};

/* TODO 날짜 문제 처리 필요 mongodb 와 표시 되는 데이터 상의 불일치 해결 필요 */
export function getDate(source: Date | string | number): Date {
  let date: Date = new Date();
  if (typeof source === 'string') {
    date = new Date(`${source?.replace(' ', 'T')}Z`);
  } else if (typeof source === 'number') {
    // timestamp
    date = new Date(source);
  }
  date.setHours(date.getHours() + 9);

  return date;
}

export function getReplaceDate(source: string): Date {
  return getDate(new Date(`${source.replace(' ', 'T')}Z`).getTime());
}

export function timestampToDate(timestamp: number): number {
  const DAY_1_TIMESTAMP = 24 * 60 * 60 * 1000;
  return Math.ceil(timestamp / DAY_1_TIMESTAMP) - 1;
}

export const timestampToDateString = (source: number, time = 0): string => {
  const date: Date = new Date(source);
  date.setHours(date.getHours() + time);

  return `${getYmdKR(date)} ${getDayOfWeek(date)} ${getHiKR(date)}`;
};

export function getRemainDate(timestamp: number): number {
  const currentTimestamp = new Date().getTime();
  const remainTime = timestamp - currentTimestamp;
  return timestampToDate(remainTime);
}

// export function timestampToHi(value: number): string {
//   const seconds = (value % 60).toString();
//
//   return `${Math.floor(value / 60).toString()}:${(seconds.length === 1 ? '0' : '') + seconds}`;
// }

export const timestampToStringTypeC = (source: number) => {
  return `${getYmd(getDate(source), '.')} (${getDayOfWeek(getDate(source))}) ${getHiAMPM(source)}`;
};
