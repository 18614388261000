import React, { PropsWithChildren } from "react";
import { useHistory } from 'react-router';
import { BackButtonIcon, ClosePageIcon, TypoLogoIcon } from "../../assets/icons";

type BackPressHeaderWrapperProps = {
  label?: string;
  goBack?: () => void;
  canGoBack?: boolean;
  otherButton?: boolean;
  isClose?: boolean;
} & PropsWithChildren

const BackPressHeaderWrapper: React.FC<BackPressHeaderWrapperProps> = ({ label, goBack, canGoBack, otherButton = false, children, isClose = false }) => {
  const navigation = useHistory();
  return (
    <div className="w-full flex flex-row justify-center items-center h-[60px] bg-zp-primary">
      <div className="absolute left-4">
        {canGoBack ? (
          <button
            type="button"
            className="flex flex-row justify-center items-center"
            onClick={() => {
              console.log("back click");
              if (goBack) {
                goBack();
                return false;
              }
              navigation.goBack();
            }}
          >
            <BackButtonIcon />
          </button>
        ) : null}
        {otherButton ? (
          <div>
            {children}
          </div>
        ) : null}
      </div>
      {isClose ? (
        <button type="button" onClick={() => navigation.goBack()} className="absolute right-4">
          <ClosePageIcon />
        </button>
      ) : null}
      {!isClose ? label ? <span className="text-xl leading-6 font-semibold text-zp-white">{label}</span> : <TypoLogoIcon /> : null}
    </div>
  );
};

export default BackPressHeaderWrapper;
