import React from 'react';
import styled from 'styled-components';

const Loading: React.FC = () => {
  return (
    <Background>
      <div className="loader">loading</div>
      <span className="text-base font-semibold text-zp-primary text-center">
        잠시만 기다려주세요...
      </span>
    </Background>
  );
};

export default Loading;

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #ffffffb7;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  .loader {
    z-index: 9999;
    margin: 60px auto;
    font-size: 5px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(245, 149, 149, 0.2);
    border-right: 1.1em solid rgba(245, 149, 149, 0.2);
    border-bottom: 1.1em solid rgba(245, 149, 149, 0.2);
    border-left: 1.1em solid #ff90a0;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
