import React, { useCallback, useEffect } from "react";
import { useHistory } from 'react-router';
import { useRecoilCallback } from "recoil";
import { kakaoLogin } from '../api/login';
import Loading from '../components/Loading';
import { currentGroupState } from "../recoil/group/atoms";
import { loadMyGroup } from "../api/group";

const KakaoLoginRedirect: React.FC = () => {
  const navigation = useHistory();
  const login = useRecoilCallback(({set}) => async () => {
    const url = new URL(window.location.href);
    const code = url.searchParams.get('code');
    console.log(code);
    const accessToken = await kakaoLogin(code || '');
    if (!accessToken) return;
    console.log('zip hap token', accessToken);
    sessionStorage.setItem('accessToken', accessToken);
    const joinInfo = sessionStorage.getItem('currentGroupLinkId');
    if (joinInfo) {
      navigation.replace(`/join/${joinInfo}`);
      return false;
    }
    const groupData = await loadMyGroup(accessToken);
    if (groupData) {
      set(currentGroupState, {
        ...groupData
      })
      console.log(groupData);
      navigation.replace('/home');
      sessionStorage.setItem('currentGroupLinkId', groupData?.linkId);
      return false;
    }
    navigation.replace("/group")
  }, [navigation]);

  useEffect(() => {
    login()
  }, [login])

  return <Loading />;
};

export default React.memo(KakaoLoginRedirect);
