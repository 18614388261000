import React, { useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import BackButtonHeader from "../components/BackButtonHeader";
import "./CreateHeart.scss";
import { loadingState } from "../recoil/atoms";
import Loading from "../components/Loading";
import { replyLetter } from "../api/letter";

const ReplyHeartPage: React.FC = () => {
  const navigation = useHistory();
  const { heartId } = useParams<{ heartId: string }>();
  const [replyId] = useState(heartId);
  const [keyword, setKeyWord] = useState('');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useRecoilState(loadingState);
  const errorMessage = useMemo(() => {
    if (!keyword) return "키워드를 선택해주세요.";
    if (!content.length) return "메세지를 작성해주세요.";
    return '';
  }, [content, keyword]);
  console.log(replyId);
  const replyHeart = async () => {
    try {
      setLoading(true);
      const letter = await replyLetter({
        content,
        heartType: keyword,
        heartId: replyId
      });
      console.log(letter);
      navigation.replace('/heart');
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />
  return (
    <div>
      <BackButtonHeader label="속마음 작성" canGoBack />
      <div className="w-full max-h-screen overflow-y-scroll py-9 px-6">
        <div className="grid grid-cols-2 gap-2 mb-9">
          {['고마워요' , '미안해요' , '부탁해요', '서운해요'].map(e => {
            return (
              <button
                type="button"
                onClick={() => {
                  setKeyWord(e);
                }}
                className={`
                  border rounded-xl py-4
                  text-[18px] leading-[18px] font-semibold text-zp-primary
                  ${keyword === e
                  ? 'border-zp-primary bg-zp-primary10'
                  : "border-zp-primary30"
                }
                `}
              >
                {e}
              </button>
            )
          })}
        </div>
        <textarea
          className="w-full notes"
          placeholder="메세지를 작성해주세요."
          value={content}
          onChange={(e) => {
            const newVal = e.target.value;
            if (newVal.length <= 200) {
              setContent(newVal);
            } else if (newVal.length > 200) {
              setContent(newVal.slice(0, 200));
            }
          }}
        />
        <div className="flex justify-end">
          <span
            className="w-full text-sm font-normal text-zp-black02 text-right mt-3">
            {content.length} / 200
          </span>
        </div>
      </div>
      <div
        className={`
              absolute bottom-0 w-full flex justify-center items-center mb-4
            `}>
        <button
          type="button"
          className={`
                text-xl font-semibold leading-6 w-full mx-6 py-4 rounded-2xl
                ${errorMessage ? 'bg-white border border-zp-grey01 text-zp-grey01' : 'bg-zp-primary text-zp-white'}
              `}
          onClick={() => {
            if (errorMessage) {
              return false;
            }
            replyHeart();
          }}
        >
          보내기
        </button>
      </div>
    </div>
  )
}

export default ReplyHeartPage;
