import React from "react";

const MinusIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="2"
      fill="none"
      viewBox="0 0 20 2"
    >
      <path fill="#FF90A0" d="M19 0H1a1 1 0 100 2h18a1 1 0 100-2z" />
    </svg>
  );
}

export default MinusIcon;
