import React, { useCallback, useMemo, useState } from "react";
import { useRecoilCallback, useRecoilState, useSetRecoilState } from "recoil";
import { useHistory } from "react-router";
import { ProfileForm } from '../../components';
import './GroupContainer.component.scss';
import { OtherGroupIcon, ProfileIcon } from '../../assets/icons';
import Modal from '../../components/Modal/Modal';
import { userState } from '../../recoil/user/atom';
import { loadingState, toastMessagesState } from "../../recoil/atoms";
import Loading from "../../components/Loading";
import { createGroup } from "../../api/group";
import { currentGroupState } from "../../recoil/group/atoms";
import BackButtonHeader from "../../components/BackButtonHeader";

const 프로필_이미지 = 'https://img.insight.co.kr/static/2016/05/03/700/ky7t1h66e7uono4ej18h.jpg';

type GroupJoinModalProps = {
  isOpen: boolean;
  setOpen: (v: boolean) => void;
};

const GroupJoinModal: React.FC<GroupJoinModalProps> = ({ isOpen, setOpen }) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setOpen}>
      <div className="w-full mx-6 bg-white flex flex-col justify-center items-center p-5 rounded-3xl ">
        <span className="text-zp-black01 text-base font-semibold leading-4 mb-3 mt-3">
          그룹 초대 링크가 필요해요!
        </span>
        <span className="text-center text-base text-zp-black01 mb-9">
          그룹 생성 페이지를 종료하고
          <br />
          초대 링크를 통해 다시 접속하면
          <br />
          그룹에 참여할 수 있어요.
        </span>
        <button
          className="w-full rounded-xl bg-zp-primary py-4 text-lg text-white font-semibold leading-[18px]"
          type="button"
          onClick={() => {
            setOpen(false);
          }}
        >
          확인
        </button>
      </div>
    </Modal>
  );
};

type ProfileRemoveModalProps = {
  isOpen: boolean;
  setOpen: (v: boolean) => void;
  removeProfile: () => void;
}

export const ProfileRemoveModal: React.FC<ProfileRemoveModalProps> = ({ isOpen, setOpen, removeProfile }) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setOpen}>
      <div className="absolute bottom-0 w-full w-full mx-6 flex flex-col justify-center items-center p-5 rounded-3xl ">
        <button
          className="
            w-full rounded-xl bg-zp-white py-4 mb-4
            text-xl text-tr-black01 font-normal leading-5 mx-6
          "
          type="button"
          onClick={removeProfile}
        >
          기본 이미지로 변경
        </button>
        <button
          className="
            w-full rounded-xl bg-zp-white py-4
            text-xl text-tr-black01 font-semibold leading-5 mx-6
          "
          type="button"
          onClick={() => {
            setOpen(false);
          }}
        >
          실행취소
        </button>
      </div>
    </Modal>
  );
};

const GroupContainer: React.FC = () => {
  const [user] = useRecoilState(userState);
  const navigation = useHistory();
  const setToasts = useSetRecoilState(toastMessagesState);
  const [loading, setLoading] = useRecoilState(loadingState)
  console.log("user", user);
  const [profileImage, setProfileImage] = useState(user?.memberProfilePicture ?? '');
  const [isOpen, setOpen] = useState(false);
  const [isProfileRemoveOpen, setProfileRemoveOpen] = useState(false);
  const [name, setName] = useState(user?.memberName ?? '');
  const [groupName, setGroupName] = useState('');
  const [isReadyJoin, setIsReadyJoin] = useState(false);

  const errorMessage = useMemo(() => {
    if (!name) return '이름을 입력해주세요.';
    if (!groupName) return '그룹명을 입력해주세요.';
    return '';
  }, [groupName, name]);

  const createMyGroup = useRecoilCallback(({ set }) => async () => {
    try {
      setLoading(true);
      const groupData = await createGroup({
        groupName,
        ownerName: name
      });
      if (!groupData) return false;
      set(currentGroupState, {
        ...groupData
      });
      console.log(groupData);
      sessionStorage.setItem('currentGroupLinkId', groupData?.linkId);
      navigation.replace("/home")
    } catch (err) {
      setToasts((origin) => origin.concat(['그룹 생성 중 문제가 발생했습니다.']))
    } finally {
      setLoading(false);
    }
  }, [groupName, name, setLoading, setToasts]);


  if (loading) return <Loading />
  return (
    <>
      <GroupJoinModal isOpen={isOpen} setOpen={setOpen} />
      <ProfileRemoveModal
        isOpen={isProfileRemoveOpen}
        setOpen={setProfileRemoveOpen}
        removeProfile={() => {
          setProfileImage('');
          setProfileRemoveOpen(false);
        }}
      />
      <div className="flex flex-col w-full h-full justify-between items-center">
        <BackButtonHeader canGoBack={false} />
        <ProfileForm
          name={name}
          setName={setName}
          onPressProfile={() => {
            setProfileRemoveOpen(true);
          }}
          profileImage={profileImage}
        />
        {!isReadyJoin ? (
          <div className="flex flex-col items-center">
            <div className="flex flex-col w-full items-center mb-4">
              <div className="flex justify-between items-center py-4 px-5 border border-zp-primary rounded-2xl">
                <span className="leading-4 font-semibold font-base text-zp-primary mr-4">
                  그룹명
                </span>
                <input
                  value={groupName}
                  onChange={(e) => {
                    if (e.target.value.length <= 6) {
                      setGroupName(e.target.value);
                    } else if (e.target.value.length > 6) {
                      setGroupName(e.target.value.slice(0, 6));
                    }
                  }}
                  maxLength={6}
                  className="px-4 border-l bg-zp-white text-zp-black03 border-zp-primary"
                  placeholder="그룹명을 입력해주세요"
                />
                <span className="text-normal text-sm leading-4 text-zp-black02 hover:border-none">
                  {groupName.length}/6
                </span>
              </div>
            </div>
            <button
              type="button"
              className={`
                w-full flex justify-center items-center
                border rounded-2xl 
                mx-5 py-4 mb-4
                leading-6 text-xl font-semibold
                ${
                  name && groupName
                    ? 'bg-zp-primary text-white border-zp-primary'
                    : 'text-zp-black02 border-zp-grey01'
                }
              `}
              onClick={() => {
                if (errorMessage) {
                  setToasts((origin) => origin.concat([errorMessage]))
                  return false;
                }
                setIsReadyJoin(true);
              }}
            >
              그룹 생성하기
            </button>
            <button
              onClick={() => {
                setOpen(true);
              }}
              type="button"
              className="flex flex-row justify-center items-center mb-10"
            >
              <OtherGroupIcon />
              <span className="text-sm underline text-zp-grey02">혹시 그룹 참여를 원하시나요?</span>
            </button>
          </div>
        ) : (
          <div className="w-full flex flex-col items-center mb-9 px-6">
            <div className="flex w-full justify-center items-center mb-4">
              <ProfileIcon />
              <span className="font-normal text-base leading-4 text-zp-primary ml-2">
                <strong className="font-semibold">{name}</strong> 님의 {groupName}
              </span>
            </div>
            <button
              type="button"
              onClick={() => {
                createMyGroup()
              }}
              className="
                w-full flex justify-center items-center
                rounded-2xl bg-zp-primary py-4
                font-semibold leading-6 text-xl text-white
              "
            >
              집안일 참여하기
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default GroupContainer;
