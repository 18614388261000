import React from "react";

const DelayWorkIcon:React.FC<{ color?: string }> = ({ color = '#D1D0D0' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      fill="none"
      viewBox="0 0 13 12"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M6.636 1.05a1 1 0 011.414 0l4.233 4.233.01.01a.996.996 0 010 1.414l-.01.009L8.05 10.95a1 1 0 01-1.414-1.415L9.172 7H1a1 1 0 010-2h8.171L6.636 2.464a1 1 0 010-1.414z"
        clipRule="evenodd"
       />
    </svg>
  );
}

export default DelayWorkIcon;
