import React, { useState } from "react";
import { useRecoilState } from "recoil";
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BackButtonHeader from "../components/BackButtonHeader";
import { ArrowIcon, XsProfileIcon } from "../assets/icons";
import { currentGroupState } from "../recoil/group/atoms";
import ExitGroupIcon from "../assets/icons/ExitGroup";
import Modal from "../components/Modal/Modal";
import { toastMessagesState } from "../recoil/atoms";
import { BASE_URL } from "../config/app";

type ExitModalProps ={
  isOpen: boolean;
  setOpen: (v: boolean) => void;
  exit: () => void
}

const ExitModal: React.FC<ExitModalProps> = ({isOpen, setOpen, exit}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setOpen}>
      <div className="w-full mx-6 bg-white flex flex-col justify-center items-center p-5 rounded-3xl ">
        <span className="w-full text-center text-zp-black01 text-xl pb-3 font-semibold leading-4 mb-6 mt-3 border-b">
          그룹 나가기
        </span>
        <span className="text-center text-base text-zp-red02 font-semibold">
          그룹에서 나가는 즉시
          <br />
          사용자의 모든 데이터가 삭제되며,
          <br />
          복구할 수 없습니다.
        </span>
        <span className="text-base text-zp-black03 my-4 mb-9">
          정말로 그룹에서 나갈까요?
        </span>
        <div className="w-full grid grid-cols-2 gap-2">
          <button
            className="w-full rounded-xl bg-zp-primary py-4 text-lg text-white font-semibold leading-[18px]"
            type="button"
            onClick={() => {
              exit();
              setOpen(false);
            }}
          >
            나가기
          </button>
          <button
            className="w-full rounded-xl border border-zp-primary text-zp-primary bg-zp-primary10 py-4 text-lg font-semibold leading-[18px]"
            type="button"
            onClick={() => {
              setOpen(false);
            }}
          >
            남아있기
          </button>
        </div>
      </div>
    </Modal>
  )
}

const OtherMenuPage:React.FC = () => {
  const [group] = useRecoilState(currentGroupState);
  const [exitModalVisible, setExitModalVisible] = useState(false);
  const [_, setToasts] = useRecoilState(toastMessagesState);
  const exit = () =>{
    console.log('exit')
  }

  const copy = () => {
    setToasts((origin) => origin.concat("초대 링크가 복사되었습니다."))
  }
  return (
    <>
      <ExitModal isOpen={exitModalVisible} setOpen={setExitModalVisible} exit={exit} />
      <div>
        <BackButtonHeader canGoBack={false} otherButton={false} isClose />
        <div className="w-full max-h-screen overflow-y-scroll">
          <div className="pt-9 pb-1 px-6 border-b">
            <div className="border-b  border-b-zp-primary30 pb-3">
              <span className="text-2xl text-zp-primary leading-6 font-semibold">{group?.groupName ?? ''}</span>
            </div>
            <div>
              <div className="py-4 flex items-center">
                <div className="bg-zp-primary w-[24px] h-[24px] flex justify-center items-center rounded-full mr-2">
                  <XsProfileIcon />
                </div>
                <span className="text-base leading-4 font-normal text-zp-black03">{group?.ownerName ?? ''}</span>
              </div>
              {group?.memberId ? (
                <div className="py-4 flex items-center border-t">
                  <div className="bg-zp-secondary w-[24px] h-[24px] flex justify-center items-center rounded-full mr-2">
                    <XsProfileIcon />
                  </div>
                  <span className="text-base leading-4 font-normal text-zp-black03">{group?.memberName ?? ''}</span>
                </div>
              ): (
                <CopyToClipboard text={`${BASE_URL}/join/${group?.linkId ?? ''}`} onCopy={copy}>
                  <button
                    type="button"
                    className="
                      py-4 text-zp-primary text-[18px] font-semibold leading-5
                      my-4 bg-zp-primary10 border border-zp-primary
                      flex justify-center items-center w-full rounded-xl
                    "
                  >
                    초대 링크 복사하기
                  </button>
                </CopyToClipboard>
              )}
            </div>
          </div>
          <div className="border-b border-b-8 border-b-zp-grey01">
            <button
              type="button"
              className="w-full py-6 px-6 flex justify-between items-center"
              onClick={() => {
                console.log('exit modal open')
                setExitModalVisible(true)
              }}
            >
              <span className="text-base leading-4 font-normal text-zp-black03">그룹 나가기</span>
              <ExitGroupIcon />
            </button>
          </div>
          <div>
            <button type="button" className="w-full py-6 px-6 flex justify-between items-center border-b">
              <span className="text-base leading-4 font-normal text-zp-black03">속마음 보관함</span>
              <div className="rotate-180">
                <ArrowIcon />
              </div>
            </button>
            <button type="button" className="w-full py-6 px-6 flex justify-between items-center border-b">
              <span className="text-base leading-4 font-normal text-zp-grey02">회원탈퇴</span>
            </button>
            <div className="w-full py-6 px-6 flex justify-between items-center border-b">
              <span className="text-base leading-4 font-normal text-zp-grey02">문의사항</span>
              <button type="button" className="underline text-zp-black02 text-sm leading-4">
                이메일 주소 복사
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default OtherMenuPage;
