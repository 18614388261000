import React from "react";

const EmptyWorkIllust: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      height="168"
      fill="none"
      viewBox="0 0 140 168"
    >
      <path
        fill="#E8E8E8"
        d="M72.97 64.57a4.2 4.2 0 010 5.94l-53.457 53.457a4.2 4.2 0 01-5.94-5.94L67.03 64.57a4.2 4.2 0 015.94 0z"
       />
      <path
        fill="#E8E8E8"
        d="M67.03 64.57a4.2 4.2 0 015.94 0l53.457 53.457a4.2 4.2 0 01-5.94 5.94L67.03 70.51a4.2 4.2 0 010-5.94zM63 110.6a4.2 4.2 0 11-8.4 0 4.2 4.2 0 018.4 0zM85.4 110.6a4.2 4.2 0 11-8.4 0 4.2 4.2 0 018.4 0z"
       />
      <path
        fill="#E8E8E8"
        fillRule="evenodd"
        d="M36.4 30.8c0-9.278 7.522-16.8 16.8-16.8h33.6c9.278 0 16.8 7.522 16.8 16.8s-7.522 16.8-16.8 16.8H75.94l-3.96 3.96a2.8 2.8 0 01-3.96 0l-3.96-3.96H53.2c-9.278 0-16.8-7.522-16.8-16.8zM70 35a4.2 4.2 0 100-8.4 4.2 4.2 0 000 8.4zm18.2-4.2a4.2 4.2 0 11-8.4 0 4.2 4.2 0 018.4 0zM56 35a4.2 4.2 0 100-8.4 4.2 4.2 0 000 8.4z"
        clipRule="evenodd"
       />
      <path
        fill="#E8E8E8"
        d="M47.6 147a4.2 4.2 0 014.2-4.2h36.4a4.2 4.2 0 110 8.4H51.8a4.2 4.2 0 01-4.2-4.2z"
       />
      <path
        fill="#E8E8E8"
        fillRule="evenodd"
        d="M70 133c-7.732 0-14 6.268-14 14h-8.4c0-12.371 10.029-22.4 22.4-22.4 12.371 0 22.4 10.029 22.4 22.4H84c0-7.732-6.268-14-14-14z"
        clipRule="evenodd"
       />
    </svg>
  );
}

export default EmptyWorkIllust;
