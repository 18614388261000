/* color */
export const primaryColor = '#FF90A0';
export const secondaryColor = '#6BEFBF';
export const goodColor = '#6BEFBF';
export const warningColor = '#ECF867';
export const badColor = '#F94DBE';
export const unSelectedColor = '#D1D0D0';
export const disabledColor = '#E8E8E8';
export const whiteColor = '#ffffff';

export const kakaoColor = '#FEE500';
export const googleColor = '#4285F4';

/* spacing */
export const defaultGrid = 4;
