import React, { useEffect } from "react";
import styled from 'styled-components';
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useHistory } from "react-router";
import { LogoIcon, TypoIcon } from '../../assets/icons';
import { LoginButtons } from '../../components';
import { primaryColor } from '../../theme/colors';
import { GOOGLE_AUTH_URL, KAKAO_AUTH_URL } from '../../api/login';
import { userState } from "../../recoil/user/atom";
import { loadMyGroup } from "../../api/group";
import { currentGroupState } from "../../recoil/group/atoms";

const LoginContainer: React.FC = () => {
  console.log(GOOGLE_AUTH_URL);
  const navigation = useHistory();

  const user = useRecoilValue(userState);
  const setGroup = useSetRecoilState(currentGroupState)
  useEffect(() => {
    const autoLogin = async () => {
      const token = sessionStorage.getItem('accessToken')
      if (token) {
        const groupData = await loadMyGroup(token);
        if (!groupData) {
          navigation.replace('/group');
          return false;
        }
        setGroup({
          ...groupData
        });
        console.log(groupData);
        sessionStorage.setItem('currentGroupLinkId', groupData?.linkId);
        navigation.replace("/home")
      }
    }
    autoLogin();
  },[navigation, setGroup, user])

  return (
    <Container>
      <IconWrapper>
        <LogoIcon />
        <TypoIcon />
        <span>집안일의 합을 맞추다</span>
      </IconWrapper>
      <LoginButtons kakaoLogin={KAKAO_AUTH_URL} googleLogin={GOOGLE_AUTH_URL} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex: 1;
  span {
    padding: 1vh 0;
    color: ${primaryColor};
  }
`;

export default LoginContainer;
