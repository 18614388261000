import React, { useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import BackButtonHeader from "../components/BackButtonHeader";
import {
  EmptyChartIcon,
  GoodWorkIcon,
  LittleManyWorkIcon,
  ManyWorkIcon,
  OtherMenuIcon,
  XsProfileIcon
} from "../assets/icons";
import DateTimeSelector from "../components/DateTimeSelector";
import { analyzationByGroupState } from "../recoil/chart/atoms";
import "./ChartPage.scss"
import { loadingState } from "../recoil/atoms";
import Loading from "../components/Loading";
import { getAnalyzationData } from "../api/analysis";
import { isOwnerState, userState } from "../recoil/user/atom";
import { currentGroupState } from "../recoil/group/atoms";

const ChartPage: React.FC = () => {
  const navigation = useHistory();
  const [analy, setAnaly] = useRecoilState(analyzationByGroupState);
  const group = useRecoilValue(currentGroupState)
  console.log("analy", analy);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [dateMode, setDateMode] = useState("week");
  const isOwner = useRecoilValue(isOwnerState)
  const user = useRecoilValue(userState);
  const [loading, setLoading] = useRecoilState(loadingState);
  const refreshChart = async () => {
    try {
      setLoading(true)
      setAnaly(await getAnalyzationData() as []);
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }
  const iconLabel = ["잘하고_있어요", "너무_적어요", "너무_많아요"];
  const label = ["가사 분담을 잘하고 있어요!", "일이 너무 적어요!", "일이 너무 많아요!"];
  const icon = [<GoodWorkIcon />, <LittleManyWorkIcon />, <ManyWorkIcon />];
  if (loading) return <Loading />
  return (
    <>
      <div>
        <BackButtonHeader canGoBack={false} otherButton>
          <button type="button" onClick={() => {
            navigation.push('/other-menu')
          }}>
            <OtherMenuIcon />
          </button>
        </BackButtonHeader>
        <div className="w-full max-h-screen overflow-y-scroll py-9 px-6">
          <div className="flex w-full justify-between mb-6">
            <DateTimeSelector
              selectMode={dateMode}
              date={currentDate}
              prevDate={() => {}}
              nextDate={() => {}}
            />
            <div>
              <button
                onClick={refreshChart}
                type="button"
                className="border py-1 px-2 text-zp-grey02 border-zp-grey02 text-xs rounded-full"
              >
                새로고침
              </button>
            </div>
          </div>
          {(analy || []).length === 2 ? (
            <>
              <div className="mb-9 flex flex-col w-full p-5 rounded-xl shadow-[0_0_5px_0_#1C151532]">
                <div className="flex justify-between items-center border-b pb-2 mb-3">
                  <span className="text-base leading-4 font-semibold text-zp-black03">분담 비율</span>
                  <div>
                    {icon[iconLabel.findIndex(e => e === (analy[isOwner ? 0 : 1]?.shareRatioType))]}
                  </div>
                </div>
                <span className="text-base leading-4 font-normal text-zp-black03">
                <span className="font-semibold">{user?.memberName} </span>
                님의 {label[iconLabel.findIndex(e => e === (analy[isOwner ? 0 : 1]?.shareRatioType))]}
              </span>
              </div>
              <span className="text-sm leading-4 font-normal text-zp-grey02 mb-4">{currentDate.getMonth() + 1}월 시작일 ~ 어제까지</span>
              <div className="mb-3 flex flex-col w-full p-5 rounded-xl shadow-[0_0_5px_0_#1C151532]">
                <span className="text-base leading-4 font-semibold text-zp-black03 border-b pb-3">집안일 분담 비율</span>
                <div className="pt-3 flex items-center">
                  <S.CircleBorder className="bg-zp-primary" per={(analy[0]?.shareRatioPercent ?? 0).toFixed(0) *  3.6}>
                    <S.Circle>
                      <S.CircleBorderSec per={(analy[1]?.shareRatioPercent ?? 0).toFixed(0) *  3.6}>
                        <S.CircleSec className="w-full flex justify-center items-center">
                          <div className="flex flex-col justify-center items-center w-full rotate-[-90deg] px-3">
                            <span className="text-[20px] leading-5 text-zp-primary my-1 font-medium">{(analy[0]?.shareRatioPercent ?? 0).toFixed(0)}%</span>
                            <div className="h-[1px] w-full bg-zp-black02 mx-3" />
                            <span className="text-[20px] leading-5 text-zp-secondary my-1 font-medium">{(analy[1]?.shareRatioPercent ?? 0).toFixed(0)}%</span>
                          </div>
                        </S.CircleSec>
                      </S.CircleBorderSec>
                    </S.Circle>
                  </S.CircleBorder>
                  <div className="ml-3">
                    <div className="py-2 flex items-center">
                      <div className="bg-zp-primary w-[6px] h-[6px] flex justify-center items-center rounded-full mr-2" />
                      <span className="text-base leading-4 font-normal text-zp-black03">{group?.ownerName ?? ''}</span>
                    </div>
                    <div className="py-2 flex items-center">
                      <div className="bg-zp-secondary w-[6px] h-[6px] flex justify-center items-center rounded-full mr-2">
                        <XsProfileIcon />
                      </div>
                      <span className="text-base leading-4 font-normal text-zp-black03">{group?.memberName ?? ''}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full grid grid-cols-2 gap-2">
                <div className="mb-9 flex flex-col w-full p-5 rounded-xl shadow-[0_0_5px_0_#1C151532]">
                  <span className="text-base font-semibold pb-3 border-b leading-4">가장 잘한 일</span>
                  <span className="text-base leading-4 font-normal mt-3">{analy[isOwner ? 0 : 1]?.bestHouseWorkTitle ?? ''}</span>
                </div>
                <div className="mb-9 flex flex-col w-full p-5 rounded-xl shadow-[0_0_5px_0_#1C151532]">
                  <span className="text-base font-semibold pb-3 border-b leading-4">가장 많이 한 일</span>
                  <span className="text-base leading-4 font-normal mt-3">{analy[isOwner ? 0 : 1]?.mostTitle ?? ''}</span>
                </div>
              </div>
            </>
          ): (
            <div className="w-full flex flex-col items-center py-[3.75rem]">
              <EmptyChartIcon />
              <span className="text-base font-normal leading-5 mb-3 text-zp-grey02 mt-6">아직 통계가 없어요</span>
              <span className="w-full text-base leading-5 font-semibold text-zp-grey02 text-center">할일을 추가하고<br />완료해주세요!</span>
            </div>
          )}
          {/* <div className="mb-9 flex flex-col w-full p-5 rounded-xl shadow-[0_0_5px_0_#1C151532]"> */}
          {/*   <span className="text-base font-semibold pb-3 border-b leading-4">담당자 변경 추천</span> */}
          {/*   <span>기능 준비중입니다.</span> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default ChartPage;


const S = {
  Circle: styled.div`
    position: relative;
    top: 5px;
    left: 5px;
    text-align: center;
    width: 96px;
    height: 96px;
    border-radius: 100%;
    background-color: #ffffff;
  `,
  CircleBorder: styled.div<{per: number}>`
    position: relative;
    text-align: center;
    width: 106px;
    height: 106px;
    border-radius: 100%;
    rotate: 90deg;
    background: linear-gradient(${(props) => `${props.per - 180}deg`}, #FF90A0 50%, transparent 50%), linear-gradient(0deg, #FF90A0 50%, white 50%)
  `,
  CircleSec: styled.div`
    position: relative;
    top: 5px;
    left: 5px;
    text-align: center;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: #ffffff;
  `,
  CircleBorderSec: styled.div<{per: number}>`
    position: relative;
    top: 2.5px;
    left: 2.5px;
    text-align: center;
    width: 90px;
    height: 90px;
    border-radius: 100%;
    background: linear-gradient(${(props) => `${props.per}deg`}, #6CDC54 50%, transparent 50%), linear-gradient(0deg, #6CDC54 50%, white 50%)
`
}
