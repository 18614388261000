import React from 'react';
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import * as S from './S';
import TabItem from '../TabItem';
import CreateItemButton from '../CreateItemButton';
import { currentGroupState } from "../../recoil/group/atoms";
import { toastMessagesState } from "../../recoil/atoms";


export const RouteLabels = ['home', 'chart', 'heart'];
const TAB_PAGES = ['/home', '/chart', '/heart'];

const BottomTabBar: React.FC = () => {
  const { pathname } = useLocation();
  const navigation = useHistory();
  const [group] = useRecoilState(currentGroupState);
  const [_, setToast] = useRecoilState(toastMessagesState);
  if (!TAB_PAGES.includes(pathname)) return null;
  return (
    <S.Container>
      {['홈', '통계', '속마음'].map((label, i) => {
        console.log(pathname)
        return (
          <TabItem
            label={label}
            selected={`/${RouteLabels[i]}` === pathname}
            gotoTab={() => {navigation.push(`/${RouteLabels[i]}`)}}
          />
        )
      })}
      <CreateItemButton
        mode={['/home', '/chart'].includes(pathname) ? 'work' : 'heart'}
        onPress={() => {
          if(['/home', '/chart'].includes(pathname)) {
            navigation.push('/create-work');
          } else {
            if (!group?.memberId) {
              setToast(origin => origin.concat("속마음을 전달할 상대방을 초대해주세요."))
              return false;
            }
            navigation.push('/create-heart');
          }
          console.log('button click');
        }}
        disabled={false}
      />
    </S.Container>
  );
};

export default BottomTabBar;
