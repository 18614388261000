import { atom, selector, selectorFamily } from "recoil";
import { currentGroupState } from "../group/atoms";
import { isOwnerState, userState } from "../user/atom";
import { getAllLetter, getUnreadLetter } from "../../api/letter";


// TODO: 하루에 한번씩 작성이 가능하도록 수정한다.
export type LetterType = {
  from: {
    memberAuthorityRoles: string;
    memberEmail: string;
    memberId: string;
    memberLoginRole: string;
    memberName: string;
  },
  heartId: string;
  kind: string;
  letterId: string;
  createAt: string;
  content: string;
  to: {
    memberAuthorityRoles: string;
    memberEmail: string;
    memberId: string;
    memberLoginRole: string;
    memberName: string;
  }
}

export const myLettersState = atom<LetterType[] | null>({
  key: 'MyLetterState',
  default: selector({
    key : "MyLetterState/Default",
    get: async ({ get }) => {
      const token = sessionStorage.getItem('accessToken');
      if (!token) return null;
      const allReadLetter = await getAllLetter();
      console.log("allReadLetter", allReadLetter);
      return (allReadLetter ?? []) as LetterType[];
    }
  })
});

export const myLetterIdsState = atom<string[]>({
  key: 'MyLetterIdsState',
  default: selector({
    key : "MyLetterIdsState/Default",
    get: ({ get }) => {
      const allLetter = get(myLettersState);
      // @ts-ignore
      const set = [...new Set(allLetter?.map(e => e?.heartId ?? ''))]
      console.log("set", set);
      return set ?? [];
    }
  })
})

export const myLetterByIdState = selectorFamily<LetterType[], string>({
  key: "MyLetterById",
  get: (letterId) => ({ get }) => {
    const letters = get(myLettersState);
    const letterByIds = letters?.filter(e => {
      return e.heartId === letterId
    }) ?? [];
    return letterByIds ?? [];
  }
})


export const unReadLetterState = atom<LetterType[] | null>({
  key: "UnReadLetterState",
  default: selector({
    key: "UnReadLetterState/Default",
    get: async ({ get }) => {
      const token = sessionStorage.getItem('currentGroupLinkId');
      const group = get(currentGroupState);
      const isOwner = get(isOwnerState);
      const userId = isOwner ? group?.memberId : group?.ownerId;
      const user = get(userState);
      console.table(user);
      console.log(isOwner, userId, group?.ownerId, group?.memberId ?? '');
      if (!token || !userId) return null;
      const unReadLetter = await getUnreadLetter(userId);
      console.log("unReadLetter", unReadLetter);
      return (unReadLetter ?? []) as LetterType[];
    }
  })
})
