import axios from "axios";
import { API_URL } from "../config/app";
import { getYmd } from "../util/date";

export type CycleType = "일_마다" | "요일_마다" | "매달";
export type DiffType = "UPPER" | 'MIDDLE' | 'LOWER' | 'DELAY';

export type WorkType = {
  isOwner: boolean;
  cycleInfo?: {
    cycleId: string;
    cycleType: CycleType
    endAt: Date | null;
    props: string | null;
    startAt: Date | null;
  },
  difficulty: DiffType;
  houseWorkId: string;
  houseWorkStatusType: "DEFAULT" | "COMPLETED" | "FAILURE" | "DELAY";
  isCycle: boolean;
  title: string;
  today: string;
}

export type MemberByWorksType = {
  works: WorkType[];
  memberAuthorityRoles: string;
  memberEmail: string;
  memberId: string;
  memberLoginRole: string;
}


export const getAllWorkByMonth = async (): Promise<WorkType[][]> => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
    }

    //TODO: 페이지네이션 구현하기
    axios.post(`${API_URL}/house-work/search-date`, {
      startAt: '2022-10-30', // yyyy-mm-dd
      endAt: '2099-10-30'
    }, { headers }).then((res) => {
      console.log(res.data.data);
      const works: WorkType[] = res.data?.data?.houseWorkInfos?.map((e: any) => {
        return e.houseWorkInfoList?.map((infos: any) => {
          const isOwner = e?.memberAuthorityRoles === 'OWNER';
          return ({
            ...infos,
            today: typeof infos.today === 'string' ? infos.today : getYmd(new Date(infos.today.join('-'))),
            isOwner
          })
        }) ?? []
      }).flat() as unknown as WorkType[]

      console.log(works);
      resolve([
        works.filter(e => e.isOwner),
        works.filter(e => !e.isOwner)
      ]);
    })
    .catch((err) => {
      console.log(err);
      reject()
    })
  })
}

type CreateWorkInput = {
  cycleType: CycleType | null;
  difficulty: DiffType;
  endAt: string | null;
  isCycle: boolean;
  props: {
    additional: number | string;
  } | null
  startAt: string | null;
  title: string
  today: string
}

export const createWork = async ({
  cycleType,
  difficulty,
  endAt,
  isCycle,
  props,
  startAt,
  title,
  today
 }: CreateWorkInput): Promise<WorkType[]> => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
    }
    console.table({
      cycleType,
      difficulty,
      endAt,
      isCycle,
      props,
      startAt,
      title,
      today
    });
    axios.post(`${API_URL}/house-work/create`,{
      cycleType,
      difficulty,
      endAt,
      isCycle,
      props,
      startAt,
      title,
      today,
    }, { headers }).then((res) => {
      console.log(res.data, "create work");
      const { list } = res.data;
      const workTypeList: WorkType[] = list.map((item: WorkType[] & any) => {
        const { groupInfo, memberInfo, ...etc } = item;
        return { ...etc }
      })
      resolve(workTypeList)
    })
      .catch((err) => {
        console.log(err);
        reject()
      })
  })
}


export const updateWorkStatus = async ({
  workId,
  status
}: { workId: string, status: "DEFAULT" | "COMPLETED" | "FAILURE" | "DELAY"}) => {
  return new Promise((resolve, reject) => {
    const headers = {
      Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
    }
    axios.post(`${API_URL}/house-work/complete`,{
      house_work_id: workId,
      houseWorkStatusType: status
    }, { headers }).then((res) => {
      console.log(res.data, "update work");
      const { success } = res.data;
      resolve(success)
    })
      .catch(err => {
        console.log(err);
        reject();
      })
  })
}
