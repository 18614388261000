import React from 'react';
import { ChartIcon, HeartIcon, HomeIcon } from '../../assets/icons';
import { primaryColor, unSelectedColor } from '../../theme/colors';
import * as S from './S';

type TabItemProps = {
  label: string;
  selected: boolean;
  gotoTab: () => void;
};

const getIcon = (name: string, selected: boolean) => {
  switch (name) {
    case '홈':
      return <HomeIcon color={selected ? primaryColor : unSelectedColor} />;
    case '통계':
      return <ChartIcon color={selected ? primaryColor : unSelectedColor} />;
    case '속마음':
      return <HeartIcon color={selected ? primaryColor : unSelectedColor} />;
    default:
      return null;
  }
};

const TabItem: React.FC<TabItemProps> = ({ label, selected, gotoTab }) => {
  return <S.Container onClick={gotoTab} >{getIcon(label, selected)}</S.Container>;
};

export default TabItem;
