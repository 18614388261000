import axios from 'axios';
import { API_URL, GOOGLE_REDIRECT_URL, KAKAO_REDIRECT_URL } from "../config/app";

export const REST_API_KEY = '44a3c35e7086a6cea10ebddd03df7aa7';
export const GOOGLE_CLIENT_SECRET = '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';
export const GOOGLE_CLIENT_ID =
  '767085427001-c220ums6k5ntb78vv7rma8ne46den43g.apps.googleusercontent.com';
export const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${KAKAO_REDIRECT_URL}&response_type=code`;
export const GOOGLE_SCOPE =
  'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';
export const GOOGLE_AUTH_URL = `https://accounts.google.com/o/oauth2/v2/auth?scope=${GOOGLE_SCOPE}&response_type=code&redirect_uri=${GOOGLE_REDIRECT_URL}&client_id=${GOOGLE_CLIENT_ID}`;

export const kakaoLogin = async (code: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `https://kauth.kakao.com/oauth/token?grant_type=authorization_code&client_id=${REST_API_KEY}&redirect_uri=${KAKAO_REDIRECT_URL}&code=${code}`,
        {}
      )
      .then((res) => {
        console.log(res);
        const { data } = res;
        const { access_token } = data;
        axios
          .post(`${API_URL}/accounts/auth/kakao/callback?token=${access_token}`)
          .then((realLoginData) => {
            console.log("realLoginData", realLoginData);
            resolve(realLoginData.data.data.zipHapJwtToken ?? '');
          });
      })
      .catch((e) => {
        console.debug(['KAKAO Login Error'], e);
        reject();
      });
  });
};

export const googleLogin = async (code: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `https://oauth2.googleapis.com/token?code=${code}&client_id=${GOOGLE_CLIENT_ID}&client_secret=${GOOGLE_CLIENT_SECRET}&redirect_uri=${GOOGLE_REDIRECT_URL}&grant_type=authorization_code`,
        {}
      )
      .then((res) => {
        console.log(res);
        const { data } = res;
        const { access_token } = data;
        axios
          .post(`${API_URL}/accounts/auth/google/callback?token=${access_token}`)
          .then((realLoginData) => {
            console.log("realLoginData", realLoginData)
            resolve(realLoginData.data.data.zipHapJwtToken ?? '');
          });
      })
      .catch((e) => {
        console.debug(['GOOGLE Login Error'], e);
        reject();
      });
  });
};
