import React from 'react';

const OtherGroupIcon: React.FC = () => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00002 13.3334C10.9455 13.3334 13.3334 10.9456 13.3334 8.00008C13.3334 5.05456 10.9455 2.66675 8.00002 2.66675C5.0545 2.66675 2.66669 5.05456 2.66669 8.00008C2.66669 10.9456 5.0545 13.3334 8.00002 13.3334ZM9.33335 6.66675C9.33335 7.40313 8.7364 8.00008 8.00002 8.00008C7.63183 8.00008 7.33335 8.29856 7.33335 8.66675V9.33341C7.33335 9.7016 7.63183 10.0001 8.00002 10.0001C8.36821 10.0001 8.66669 9.7016 8.66669 9.33341V9.2494C9.81684 8.95337 10.6667 7.90931 10.6667 6.66675C10.6667 5.19399 9.47278 4.00008 8.00002 4.00008C6.52726 4.00008 5.33335 5.19399 5.33335 6.66675C5.33335 7.03494 5.63183 7.33342 6.00002 7.33342C6.36821 7.33342 6.66669 7.03494 6.66669 6.66675C6.66669 5.93037 7.26364 5.33341 8.00002 5.33341C8.7364 5.33341 9.33335 5.93037 9.33335 6.66675ZM7.33335 11.3334C7.33335 10.9652 7.63183 10.6667 8.00002 10.6667C8.36821 10.6667 8.66669 10.9652 8.66669 11.3334C8.66669 11.7016 8.36821 12.0001 8.00002 12.0001C7.63183 12.0001 7.33335 11.7016 7.33335 11.3334Z"
      fill="#A4A2A2"
    />
  </svg>
);
export default OtherGroupIcon;
